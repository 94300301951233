import React, { Fragment } from "react";
import {
  Box,
  Typography,
  TextField,
  Link,
  CircularProgress,
  Button,
} from "@material-ui/core";
import { useAppBar } from "hooks/app_bar";
import { Logo } from "components/Logo";
import { Form } from "components/Form";
import { NativeSelect } from "components/NativeSelect";
import { useParams, Redirect, Link as RouterLink } from "react-router-dom";
import { useCreator, useStrata } from "hooks/strata";
import { LoadingButton } from "components/LoadingButton";
import { useInviteMembers } from "hooks/invite";
import { ROLES } from "utils/data";
import { CopyContainer } from "components/CopyContainer";

const StrataInvitePage = () => {
  useAppBar("Invite members");
  let { id } = useParams();

  const [document, isLoaded] = useStrata(id!);
  const isCreator = useCreator(document!);

  const [
    invites,
    errors,
    isLoading,
    ,
    handleChange,
    onSubmit,
    addInvite,
  ] = useInviteMembers(id!);

  return (
    <Box>
      {isLoaded ? (
        <>
          {!isCreator && <Redirect to={`/dashboard`} />}
          {isLoading === false ? <Redirect to={`/dashboard/${id}`} /> : <></>}
          <Logo />
          <Box mt={2} />
          <CopyContainer component={Typography} align={"center"} variant="body2">
            {
              "Great, your strata plan is now on Hello Strata. Lets invite your team of fellow committee members and strata/building manager."
            }
          </CopyContainer>
          <Box mt={4} />
          <Form onSubmit={onSubmit}>
            {invites.map((invite, index) => (
              <Fragment key={index}>
                <TextField
                  label={`Invite ${index + 1} - email address`}
                  required
                  name={"number"}
                  error={Boolean(errors[index]?.email)}
                  value={invite.email}
                  onChange={handleChange(index, "email")}
                  variant="outlined"
                  helperText={errors[index]?.email}
                />
                <Box mt={2} />
                <NativeSelect
                  label={`Invite ${index + 1} - role`}
                  required
                  onChange={handleChange(index, "role")}
                  value={invite.role}
                >
                  {ROLES.map((role) => (
                    <option key={role.code} value={role.code}>
                      {role.name}
                    </option>
                  ))}
                </NativeSelect>
                <Box mt={3} />
              </Fragment>
            ))}
            <Link href="#" onClick={addInvite}>
              {"Invite another team member"}
            </Link>
            <Box mt={2} />
            <LoadingButton loading={Boolean(isLoading)}>
              {"Send invites"}
            </LoadingButton>
            <Box mt={2} />
            <Button component={RouterLink} to={`/dashboard/${id}`} color="secondary">
              {"Skip and view dashboard"}
            </Button>
          </Form>
        </>
      ) : (
        <Box display={"flex"} justifyContent={"center"}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

export default StrataInvitePage;
