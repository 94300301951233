import React from "react";
import { Container } from "components/Container";
import { Link as RouterLink, Redirect } from "react-router-dom";
import { NavigationBar } from "components/NavigationBar";
import { AbsoluteContainer } from "components/Container";
import { useAppBar } from "hooks/app_bar";
import { Button, Box, TextField } from "@material-ui/core";
import { Form } from "components/Form";
import { LoadingButton } from "components/LoadingButton";
import { useProfilePassword } from "hooks/profile";
import { useQuery } from "hooks/helpers";
import { isEmpty } from "utils/helpers";

const ChangePasswordPage = () => {
  const query = useQuery();
  useAppBar(undefined, false);

  const [data, errors, isLoaded, handleChange, onSubmit] = useProfilePassword();

  return (
    <AbsoluteContainer>
      {isLoaded === true && isEmpty(errors) && (
        <Redirect to={query.get("redirect") || "/profile"} />
      )}
      <NavigationBar
        title={"Change password"}
        backTo={query.get("redirect") || "/profile"}
      />
      <Container>
        <Form onSubmit={onSubmit}>
          <Box mt={2} />
          <TextField
            label="Current password"
            type="password"
            value={data.current_password}
            error={Boolean(errors.current_password)}
            helperText={errors.current_password}
            onChange={handleChange("current_password")}
            variant="outlined"
          />
          <Box mt={6} />
          <TextField
            label="New password"
            type="password"
            value={data.new_password}
            error={Boolean(errors.new_password)}
            helperText={errors.new_password || "Min 6 characters"}
            onChange={handleChange("new_password")}
            variant="outlined"
          />
          <Box mt={2} />
          <TextField
            label="Re-enter new password"
            type="password"
            value={data.confirm_new_password}
            error={Boolean(errors.confirm_new_password)}
            helperText={errors.confirm_new_password}
            onChange={handleChange("confirm_new_password")}
            variant="outlined"
          />
          <Box mt={2} />
          <LoadingButton loading={isLoaded === false}>
            {"Change password"}
          </LoadingButton>
          <Box mt={2} />
          <Button
            component={RouterLink}
            to={query.get("redirect") || "/profile"}
            color="secondary"
          >
            {"Cancel"}
          </Button>
        </Form>
      </Container>
    </AbsoluteContainer>
  );
};

export default ChangePasswordPage;
