const FIREBASE_CONFIGS: { [k: string]: any } = {
  development: {
    apiKey: "AIzaSyBXxWFHWLWXtkZnN4Az_OCcEmVx8wQq3j4",
    authDomain: "hello-strata-dev.firebaseapp.com",
    databaseURL: "https://hello-strata-dev.firebaseio.com",
    projectId: "hello-strata-dev",
    storageBucket: "hello-strata-dev.appspot.com",
    messagingSenderId: "820231513983",
    appId: "1:820231513983:web:4596de7ffdf2dd8b75dc4a"
  },
  staging: {
    apiKey: "AIzaSyDDoOvTaDsfiEJvHcIBLMyn6IkFJ3pm3Yw",
    authDomain: "hellostrata-b6bdd.firebaseapp.com",
    databaseURL: "https://hellostrata-b6bdd.firebaseio.com",
    projectId: "hellostrata-b6bdd",
    storageBucket: "hellostrata-b6bdd.appspot.com",
    messagingSenderId: "980873427724",
    appId: "1:980873427724:web:66404855c53e98e2ffe729",
    measurementId: "G-W60S6EF6YV"
  },
  production: {
    apiKey: "AIzaSyAhTZsELwuk5UQOIsfP1R1-JLB7r0um2o8",
    authDomain: "app.hellostrata.com",
    databaseURL: "https://hellostrata-prod.firebaseio.com",
    projectId: "hellostrata-prod",
    storageBucket: "hellostrata-prod.appspot.com",
    messagingSenderId: "553789220501",
    appId: "1:553789220501:web:1a57ff0ded19331eaadd9c",
    measurementId: "G-HDD7YS008B"
  }
};

export const FIREBASE =
  FIREBASE_CONFIGS[process.env.REACT_APP_ENV || "development"];
