import React from "react";
import { Box, makeStyles, Typography, ButtonBase } from "@material-ui/core";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import GridOnIcon from "@material-ui/icons/GridOn";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import { classnames } from "utils/helpers";

const icons: { [key: string]: any } = {
  "application/msword": InsertDriveFileIcon,
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": InsertDriveFileIcon,
  "application/pdf": PictureAsPdfIcon,
  "application/vnd.ms-excel": GridOnIcon,
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": GridOnIcon,
};

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: "start",
    position: "relative",
    height: 80,
    width: "100%",
    maxWidth: 80,
  },
  button: {
    width: 80,
    height: 80,
    display: "flex",
    flexShrink: 0,
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    overflow: "hidden",
    borderRadius: 10,
    border: "1px solid #ccc",
    boxSizing: "border-box",
  },
  image: {
    width: "100%",
    height: "100%",
    backgroundPosition: "50% 50%",
    backgroundSize: "cover",
  },
  input: {
    display: "none",
  },
  remove: {
    position: "absolute",
    top: -10,
    right: -10,
  },
  icon: {
    color: "#fff",
  },
}));

interface FilePreviewComponentProps {
  className?: string;
  url: string;
  type?: string;
  name?: string;
  onClick: () => void;
}

export const FilePreviewComponent = ({
  className,
  url,
  name,
  type,
  onClick,
}: FilePreviewComponentProps) => {
  const classes = useStyles();

  const Icon = type ? icons[type] || InsertDriveFileIcon : InsertDriveFileIcon;

  return (
    <Box display={"flex"}>
      <ButtonBase
        className={classnames(classes.root, className)}
        onClick={onClick}
      >
        <Box className={classes.button}>
          {!Boolean(name) ? (
            <div
              className={classes.image}
              style={{ backgroundImage: `url("${url}")` }}
            />
          ) : (
            <Icon fontSize={"large"} />
          )}
        </Box>
      </ButtonBase>
      {Boolean(name) && (
        <Box pt={2} pl={2} overflow={"hidden"} pr={3}>
          <Typography
            component={Box}
            textAlign={"left"}
            overflow={"hidden"}
            textOverflow={"ellipsis"}
          >
            {name}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
