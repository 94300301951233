import { useState, useEffect } from "react";

export const useDismissibleError = (value: any, error: string | null) => {
  const [internalError, setInternalError] = useState(error);

  useEffect(() => {
    if (error) {
      setInternalError(error);
    }
  }, [error, setInternalError]);

  useEffect(() => {
    setInternalError(null);
  }, [value, setInternalError]);

  return [internalError];
};
