import firebase from "firebase/app";

export interface StrataPlanData {
  str_number: string;
  str_name: string;
  suburb: string;
  state: string;
  post_code: string;
}

export class AddressValidateError extends Error {
  public message: string;
  public name = "AddressValidateError";

  constructor(raw: any) {
    super(raw);
    this.message = raw.message;
  }

  static generate(message: String): AddressValidateError {
    return new AddressValidateError(message);
  }

  toString() {
    return `AddressValidateError: ${this.message}`;
  }
}

export const validateAddress = async (plan: StrataPlanData) => {
  const validateAddress = firebase
    .functions()
    .httpsCallable("onValidateAddress");

  try {
    const { data } = await validateAddress(plan);

    if (!data.success) {
      throw AddressValidateError.generate(data);
    }
  } catch (e) {
    throw e;
  }
};

export const toAddressString = (planData: StrataPlanData) =>
  `${planData.str_number} ${planData.str_name} ${planData.suburb}`;
