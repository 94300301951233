import React, { ReactNode, useState, cloneElement } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";

interface DeleteButtonProps {
  title?: ReactNode;
  message: ReactNode;
  children?: ReactNode;
  onDelete: () => void;
}

export const DeleteButton = ({
  title,
  message,
  children,
  onDelete,
}: DeleteButtonProps) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    handleClose();
    onDelete();
  };

  return (
    <>
      {children ? (
        cloneElement(children as any, { onClick: handleClickOpen })
      ) : (
        <Button color="secondary" onClick={handleClickOpen}>
          {"Delete request"}
        </Button>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="secondary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
