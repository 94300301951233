import { useState, useCallback } from "react";
import { useFirebase } from "react-redux-firebase";

export const useLoginMethods = (): [
  string[] | null,
  string,
  string | null,
  (email: string) => Promise<any>,
  () => void
] => {
  const firebase = useFirebase();

  const [error, setError] = useState<string | null>(null);
  const [methods, setMethods] = useState<string[] | null>(null);
  const [email, saveEmail] = useState<string>("");

  const setEmail = useCallback(
    async (email: string) => {
      saveEmail(email.trim());
      setError(null);
      try {
        const signInMethods = await firebase
          .auth()
          .fetchSignInMethodsForEmail(email);
        setMethods(signInMethods);
      } catch (e) {
        if (e.code === "auth/invalid-email") {
          console.log(e);
          setError("Please enter a valid email address.");
        } else {
          setError(e.message);
        }
      }
    },
    [firebase, setMethods, saveEmail, setError]
  );

  const reset = useCallback(() => {
    setMethods(null);
  }, [setMethods]);

  return [methods, email, error, setEmail, reset];
};
