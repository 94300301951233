import React, {
  ReactNode,
  useEffect,
  useRef,
  useState,
  useCallback,
} from "react";
import { ButtonBase, makeStyles, CircularProgress } from "@material-ui/core";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import { useFirebaseImageUpload, Nullable } from "hooks/file";
import { classnames } from "utils/helpers";
import { useFirestore } from "react-redux-firebase";
import { GenericFile } from "utils/helpers";

const types = [
  "image/*",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/pdf",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];

const useStyles = makeStyles((theme) => ({
  root: {},
  button: {
    width: 80,
    height: 80,
    position: "relative",
    overflow: "hidden",
    borderRadius: 9,
    border: "1px solid #ccc",
  },
  image: {
    width: "100%",
    height: "100%",
    backgroundPosition: "50% 50%",
    backgroundSize: "cover",
  },
  input: {
    display: "none",
  },
}));

interface RequestDetailsProps {
  className?: string;
  collection: string;
  children?: (isLoading: Nullable<boolean>) => ReactNode;
  sync?: boolean;
  imagesOnly?: boolean;
  onUpload: (file: GenericFile) => void;
}

export const FileUploader = ({
  className,
  collection,
  children,
  onUpload,
  imagesOnly = true,
  sync = true,
}: RequestDetailsProps) => {
  const classes = useStyles();

  const mounted = useRef<boolean>();

  const firestore = useFirestore();

  const [upload, isUploadLoaded, file] = useFirebaseImageUpload(collection);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  useEffect(() => {
    if (mounted.current && isUploadLoaded === true) {
      onUpload(file!);
    }
  }, [isUploadLoaded, file, onUpload]);

  const handleUpload = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (sync) {
        upload(e);
      } else {
        if (e.target.files) {
          onUpload(e.target.files[0]);
        }
      }
    },
    [sync, upload, onUpload]
  );

  return (
    <label className={classnames(classes.root, className)}>
      {children ? (
        children(isUploadLoaded)
      ) : (
        <ButtonBase component={"span"} className={classes.button} focusRipple>
          {isUploadLoaded === false ? (
            <CircularProgress size={24} />
          ) : (
            <AddAPhotoIcon fontSize={"large"} />
          )}
        </ButtonBase>
      )}
      <input
        accept={(imagesOnly ? types.slice(0, 1) : types.slice(1)).join(",")}
        className={classes.input}
        onChange={handleUpload}
        type="file"
      />
    </label>
  );
};
