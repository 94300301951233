import React from "react";
import { Container } from "components/Container";
import { Link as RouterLink, Redirect } from "react-router-dom";
import { NavigationBar } from "components/NavigationBar";
import { AbsoluteContainer } from "components/Container";
import { useAppBar } from "hooks/app_bar";
import { Button, Box, TextField, Link, Typography } from "@material-ui/core";
import { Form } from "components/Form";
import { LoadingButton } from "components/LoadingButton";
import { ProfileAvatar } from "components/ProfileAvatar";
import { useAuth, useProfileUpdate } from "hooks/profile";
import { useQuery, useFullLocation } from "hooks/helpers";
import { UserStrataItem } from "components/UserStrataItem";

const ProfilePage = () => {
  const location = useFullLocation();
  const query = useQuery();
  useAppBar(undefined, false);

  const [auth] = useAuth();

  const [
    data,
    errors,
    isLoading,
    strata,
    isStrataLoaded,
    handleChange,
    onSubmit,
  ] = useProfileUpdate();

  return (
    <AbsoluteContainer>
      {isLoading === false && (
        <Redirect to={query.get("redirect") || "/dashboard"} />
      )}
      <NavigationBar
        title={"Edit Profile"}
        backTo={query.get("redirect") || "/dashboard"}
      />
      <Container>
        <ProfileAvatar />
        <Form onSubmit={onSubmit}>
          <Box mt={4} />
          <TextField
            label="First name"
            autoFocus
            error={Boolean(errors.first_name)}
            helperText={errors.first_name}
            value={data.first_name}
            onChange={handleChange("first_name")}
            variant="outlined"
          />
          <Box mt={2} />
          <TextField
            label="Surname"
            value={data.last_name}
            error={Boolean(errors.last_name)}
            helperText={errors.last_name}
            onChange={handleChange("last_name")}
            variant="outlined"
          />
          {auth.providerData.length == 0 ?
            <Box
              mt={3}
              alignSelf={"flex-start"}
              display={"flex"}
              flexDirection={"column"}
            >
              <Typography variant={"caption"}>{"Password"}</Typography>
              <Box pt={1} />
              <Link
                component={RouterLink}
                to={`/change_password?redirect=${location}`}
              >
                {"Change Password"}
              </Link>
            </Box>
            : <Box />}
          <Box mt={3} alignSelf={"flex-start"}>
            <Typography variant={"caption"}>{"My strata plans"}</Typography>
          </Box>
          <Box mt={2} />
          {isStrataLoaded &&
            strata?.map(
              ({ id, address, role }) =>
                data[id!] !== undefined && (
                  <UserStrataItem
                    key={id}
                    address={address}
                    role={role}
                    value={data[id!]}
                    onChange={handleChange(id!)}
                  />
                )
            )}
          <LoadingButton loading={Boolean(isLoading)}>{"Save"}</LoadingButton>
          <Box mt={2} />
          <Button
            color="secondary"
            component={RouterLink}
            to={query.get("redirect") || "/dashboard"}
          >
            {"Cancel"}
          </Button>
        </Form>
      </Container>
    </AbsoluteContainer>
  );
};

export default ProfilePage;
