import { useSelector } from "react-redux";
import { isLoaded, useFirebase } from "react-redux-firebase";
import {
  FirebaseState,
  User,
  Auth,
  UserStrataPlan,
  getCredential,
} from "utils/firebase";
import { FirestoreRecord } from "./helpers";
import { useCallback, useState, useEffect, useMemo } from "react";
import { FieldValue } from "utils/firebase";
import { useBatchUpdate, BatchRecord } from "./batch";
import { Nullable } from "./file";
import { useUser } from "./user";
import { createChangeEvent, clearUndefinedOrNull } from "utils/helpers";
import { useUserStrata } from "./strata";
import {
  useForm,
  minLength,
  ValidationErrors,
  SubmitFunction,
  ChangeCallback,
} from "./form";
import { changePassword } from "services/password";

export interface ProfileData {
  first_name: string;
  last_name: string;
}

export const useProfile = (): FirestoreRecord<User> => {
  const profile = useSelector<FirebaseState, User>(
    (state) => state.firebase.profile
  );

  return [profile, isLoaded(profile), null];
};

export const useAuth = (): FirestoreRecord<Auth> => {
  const auth = useSelector<FirebaseState, Auth>((state) => state.firebase.auth);

  return [auth, isLoaded(auth), null];
};

export const useCreateProfile = (
  email: string,
  profileData: ProfileData | null
) => {
  const firebase = useFirebase();

  return useCallback(
    (password: string) => {
      firebase.createUser(
        {
          email,
          password,
        },
        {
          email,
          created_at: FieldValue.serverTimestamp(),
          ...profileData,
        }
      );
    },
    [firebase, email, profileData]
  );
};

export const useProfileLogin = (
  email: string
): [string | null, (email: string) => Promise<any>] => {
  const firebase = useFirebase();
  const [error, setError] = useState<string | null>(null);

  const login = useCallback(
    async (password: string) => {
      setError(null);
      try {
        await firebase.login({
          email,
          password,
        });
      } catch (e) {
        if (e.code === "auth/wrong-password") {
          setError(
            "Invalid password, please try again or reset your password."
          );
        } else {
          setError(e.message);
        }
      }
    },
    [firebase, email]
  );

  return [error, login];
};

export const useForgottenPassword = (): [
  string,
  boolean | null,
  string | null,
  (email: string) => void
] => {
  const [isLoading, setLoading] = useState<boolean | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [email, setEmail] = useState("");

  const firebase = useFirebase();

  const reset = useCallback(
    async (email: string) => {
      setLoading(true);
      setError(null);
      setEmail(email);
      try {
        await firebase.resetPassword(email);
      } catch (e) {
        setError(e.message);
      }
      setLoading(false);
    },
    [firebase]
  );

  return [email, isLoading, error, reset];
};

export const useResetPassword = (
  code: string | null
): [
    boolean | null,
    boolean | null,
    string | null,
    (password: string) => void
  ] => {
  const [isLoading, setLoading] = useState(false);
  const [verify, setVerify] = useState<boolean | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [email, setEmail] = useState("");

  const firebase = useFirebase();

  useEffect(() => {
    (async () => {
      setVerify(null);
      try {
        const email = await firebase.verifyPasswordResetCode(code!);
        setEmail(email);
        setVerify(true);
      } catch (e) {
        setVerify(false);
      }
    })();
  }, [firebase, code]);

  const reset = useCallback(
    async (password: string) => {
      setLoading(true);
      setError(null);
      try {
        await firebase.confirmPasswordReset(code!, password);
        await firebase.login({ email, password });
      } catch (e) {
        setError(e.message);
        setLoading(false);
      }
    },
    [firebase, code, email]
  );

  return [isLoading, verify, error, reset];
};

export interface ProfileData {
  first_name: string;
  last_name: string;
  [key: string]: string;
}

export const useProfileUpdateSubmit = (): [
  (data: ProfileData) => void,
  Nullable<boolean>,
  any
] => {
  const [auth] = useAuth();

  const [isLoading, error, update] = useBatchUpdate();

  const onSubmit = useCallback(
    (data: ProfileData) => {
      let { first_name, last_name, ...lots } = data;
      first_name = first_name.toString().trim();
      last_name = last_name.toString().trim();
      const userAction: BatchRecord = {
        id: auth.uid,
        path: `users/${auth.uid}`,
        first_name,
        last_name,
      };

      const actions = Object.keys(lots)
        .filter((key) => lots[key] !== undefined)
        .map((key) => ({
          id: key,
          path: `strata/${key}/members/${auth.uid}`,
          lot: lots[key],
        }));

      update([...actions, userAction]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [auth]
  );

  return [onSubmit, isLoading, error];
};

const PROFILE_INIT_DATA = {
  first_name: "",
  last_name: "",
};

const VALIDATORS = {
  first_name: minLength(2),
  last_name: minLength(2),
};

const emptyValidator = () => () => null;

export const useProfileUpdate = (): [
  ProfileData,
  ValidationErrors<ProfileData>,
  Nullable<boolean>,
  UserStrataPlan[],
  boolean,
  ChangeCallback<ProfileData>,
  SubmitFunction
] => {
  const [profile, isLoaded] = useUser();

  const [update, isUpdateLoading] = useProfileUpdateSubmit();

  const [data, errors, handleChange, onSubmit] = useForm<ProfileData>(
    PROFILE_INIT_DATA,
    update,
    VALIDATORS,
    emptyValidator
  );

  useEffect(() => {
    if (!isLoaded) {
      return;
    }
    handleChange("first_name")(createChangeEvent<unknown>(profile?.first_name));
    handleChange("last_name")(createChangeEvent<unknown>(profile?.last_name));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile, isLoaded]);

  const [strata, isStrataLoaded] = useUserStrata();

  useEffect(() => {
    if (!isStrataLoaded) {
      return;
    }
    strata.forEach(({ id, lot }) => {
      handleChange(id!)(createChangeEvent<unknown>(lot || ""));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [strata, isStrataLoaded]);

  return [
    data,
    errors,
    isUpdateLoading,
    strata,
    isStrataLoaded,
    handleChange,
    onSubmit,
  ];
};

export interface UserPasswordData {
  current_password: string;
  new_password: string;
  confirm_new_password: string;
}

const USER_PASSWORD_INIT_DATA = {
  current_password: "",
  new_password: "",
  confirm_new_password: "",
};

const USER_PASSWORD_VALIDATORS = {
  new_password: minLength(6),
  confirm_new_password: minLength(6),
};

const USER_PASSWORD_INITIAL_ERRORS = {
  current_password: undefined,
  new_password: undefined,
  confirm_new_password: undefined,
};

export const useProfilePassword = (): [
  UserPasswordData,
  ValidationErrors<UserPasswordData>,
  Nullable<boolean>,
  ChangeCallback<UserPasswordData>,
  SubmitFunction
] => {
  const firebase = useFirebase();
  const [isLoaded, setLoaded] = useState<Nullable<boolean>>(null);
  const [submitErrors, setSubmitErrors] = useState<
    ValidationErrors<UserPasswordData>
  >(USER_PASSWORD_INITIAL_ERRORS);

  const update = useCallback(
    async ({
      current_password,
      new_password,
      confirm_new_password,
    }: UserPasswordData) => {
      setSubmitErrors(USER_PASSWORD_INITIAL_ERRORS);
      const user = firebase.auth().currentUser;
      if (!user) {
        return;
      }
      setLoaded(false);
      try {
        await user.reauthenticateWithCredential(
          getCredential(user.email!, current_password)
        );
        if (new_password !== confirm_new_password) {
          setSubmitErrors((errors) => ({
            ...errors,
            confirm_new_password:
              "Passwords entered don't match.  Please try again",
          }));
          setLoaded(true);
          return;
        }
        await changePassword(new_password);
      } catch (e) {
        setSubmitErrors((errors) => ({
          ...errors,
          current_password: e.message,
        }));
      }
      setLoaded(true);
    },
    [firebase]
  );

  const [data, errors, handleChange, onSubmit] = useForm<UserPasswordData>(
    USER_PASSWORD_INIT_DATA,
    update,
    USER_PASSWORD_VALIDATORS
  );

  const combinedErrors = useMemo(() => {
    return {
      ...errors,
      ...clearUndefinedOrNull<ValidationErrors<UserPasswordData>>(submitErrors),
    };
  }, [errors, submitErrors]);

  return [data, combinedErrors, isLoaded, handleChange, onSubmit];
};
