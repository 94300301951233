import React, { useState, useCallback, useMemo } from "react";
import {
  Box,
  TextField,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Link,
  Typography,
} from "@material-ui/core";
import { Form } from "components/Form";
import { LoadingButton } from "./LoadingButton";
import { useForm, minLength, passwordMatch } from "hooks/form";

interface CreatePasswordParams {
  labels?: string[];
  showTerms?: boolean;
  error?: string | null;
  loading?: boolean;
  onChangeText?: string;
  onChange?: (password: string, agreeTerms: boolean) => void;
  onCancel?: () => void;
}

interface Passwords {
  password: string;
  confirm_password: string;
}

const INIT_DATA = {
  password: "",
  confirm_password: "",
};

const VALIDATORS = {
  password: minLength(6, "Password"),
  confirm_password: passwordMatch("Confirm password"),
};

export const CreatePassword = ({
  labels = ["Password", "Re-enter Password"],
  showTerms = false,
  loading = false,
  error,
  onChangeText = "Create account",
  onChange,
  onCancel,
}: CreatePasswordParams) => {
  const [agreeTerms, setAgreeTerms] = useState(false);

  const isSubmitEnabled = useMemo(() => (showTerms ? agreeTerms : true), [
    showTerms,
    agreeTerms,
  ]);

  const handleSubmit = useCallback(
    (data) => {
      onChange?.(data.password, agreeTerms);
    },
    [onChange, agreeTerms]
  );

  const [data, errors, handleChange, onSubmit] = useForm<Passwords>(
    INIT_DATA,
    handleSubmit,
    VALIDATORS
  );

  return (
    <Form onSubmit={onSubmit}>
      <Typography align={"center"} variant="body2">
        {"Please create a password."}
      </Typography>
      <Box mt={4} />
      <TextField
        label={labels[0]}
        type="password"
        autoFocus
        error={Boolean(errors.password)}
        helperText={errors.password || "Min 6 characters"}
        value={data.password}
        onChange={handleChange("password")}
        variant="outlined"
      />
      <Box mt={2} />
      <TextField
        label={labels[1]}
        type="password"
        variant="outlined"
        error={Boolean(errors.confirm_password) || Boolean(error)}
        helperText={errors.confirm_password || error}
        onChange={handleChange("confirm_password")}
        value={data.confirm_password}
      />
      <Box mt={2} />
      <LoadingButton loading={loading} disabled={!isSubmitEnabled}>
        {onChangeText}
      </LoadingButton>
      <Box mt={2} />
      <Button color="secondary" onClick={onCancel}>
        {"Cancel"}
      </Button>
    </Form>
  );
};
