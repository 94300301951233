import React, { useCallback } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  TextField,
  Box,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  FormHelperText,
  Typography,
} from "@material-ui/core";
import { useForm } from "hooks/form";
import { Form } from "./Form";
import { RequestStatus } from "utils/firebase";

export interface RequestData {
  title: string;
  status: string;
  details?: string;
  attachments?: string[];
}

interface RequestTitleProps {
  title: string;
  back: string;
  status: string;
  onChange: (data: RequestData) => void;
}

export const RequestTitle = ({
  back = "",
  title = "",
  status = "",
  onChange,
}: RequestTitleProps) => {
  const update = useCallback(
    (data: RequestData) => {
      onChange(data);
    },
    [onChange]
  );

  const [data, errors, handleChange, onSubmit] = useForm<RequestData>(
    { title, status },
    update
  );

  return (
    <Form onSubmit={onSubmit}>
      <Typography component="div" variant="body2">
        <Box>{"Start with a brief title that summarizes the issue."}</Box>
        <Box>{"For example."}</Box>
        <Box fontStyle="italic">{"Noise complaint regarding unit 5"}</Box>
        <Box fontStyle="italic">
          {"Damage to patio fencing near south entrance"}
        </Box>
      </Typography>
      <Box mt={6} />
      <TextField
        label="Title"
        value={data.title}
        error={Boolean(errors.title)}
        required={true}
        inputProps={{
          maxLength: 80,
        }}
        helperText={
          errors.title || `${80 - data.title.length} characters remain`
        }
        variant="outlined"
        onChange={handleChange("title")}
      />
      <Box mt={2} />
      <FormControl
        error={Boolean(errors.status)}
        required={true}
        component="fieldset"
      >
        <FormLabel component="legend">Add request to</FormLabel>
        <RadioGroup
          row={true}
          aria-label="gender"
          name="gender1"
          value={data.status}
          onChange={handleChange("status")}
        >
          <FormControlLabel
            value={RequestStatus.Todo}
            control={<Radio />}
            label="To do"
          />
          <FormControlLabel
            value={RequestStatus.Doing}
            control={<Radio />}
            label="Doing"
          />
          <FormControlLabel
            value={RequestStatus.Done}
            control={<Radio />}
            label="Done"
          />
          <FormControlLabel
            value={RequestStatus.OnHold}
            control={<Radio />}
            label="On hold"
          />
        </RadioGroup>
        {errors.status && <FormHelperText>{errors.status}</FormHelperText>}
      </FormControl>
      <Box mt={4} />
      <Button type="submit" variant="contained" color={"primary"}>
        {"Next"}
      </Button>
      <Box mt={2} />
      <Button component={RouterLink} to={back} color="secondary">
        {"Cancel"}
      </Button>
    </Form>
  );
};
