import React, { useCallback, useState, useRef } from "react";
import {
  Box,
  Button,
  Typography,
  InputLabel,
  makeStyles,
  Link,
  CircularProgress,
} from "@material-ui/core";
import { Form } from "./Form";
import { FileUploader } from "./FileUploader";
import { FilePreview } from "./FilePreview";
import { GenericFile } from "utils/helpers";

interface RequestDetails {
  attachments?: string[];
}

interface RequestDetailsProps {
  strataId: string;
  requestId: string;
  onChange: (data: RequestDetails) => void;
  onCancel: () => void;
}

const useStyles = makeStyles((theme) => ({
  image: {
    marginRight: 15,
    marginBottom: 15,
  },
  document: {
    display: "flex",
    marginBottom: 20,
  },
}));

export const RequestDocuments = ({
  strataId,
  requestId,
  onChange,
  onCancel,
}: RequestDetailsProps) => {
  const classes = useStyles();
  const files = useRef<Array<GenericFile>>();

  const path = `strata/${strataId}/support_requests/${requestId}`;

  const onSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      onChange({
        attachments: files.current?.map(({ name }) => `${path}/${name}`),
      });
    },
    [onChange, files, path]
  );

  const [images, setImages] = useState<Array<GenericFile>>([]);
  const [documents, setDocuments] = useState<Array<GenericFile>>([]);

  const updateFields = useCallback(async (genericFile: GenericFile) => {
    const file = genericFile.file;
    if (!files.current) {
      files.current = [genericFile];
    } else {
      if (
        files.current.find(
          (f) =>
            f.name === file?.name &&
            f.file?.lastModified === file?.lastModified &&
            f.file?.size === file?.size
        )
      ) {
        return;
      }
      files.current.push(genericFile);
    }
    if (/^image\/.*$/.test(genericFile.type)) {
      setImages((images) => [...images, genericFile]);
    } else {
      setDocuments((documents) => [...documents, genericFile]);
    }
  }, []);

  const removeFile = useCallback((file: GenericFile) => {
    if (files.current) {
      files.current = files.current.filter((f) => f !== file);
    }
    if (/^image\/.*$/.test(file.type)) {
      setImages((images) => images.filter((image) => image !== file));
    } else {
      setDocuments((documents) =>
        documents.filter((document) => document !== file)
      );
    }
  }, []);

  return (
    <Form onSubmit={onSubmit}>
      <Typography component="div" variant="body2">
        <Box>
          {
            "Please upload any relevant photos or documents (PDF, Word or Excel)."
          }
        </Box>
      </Typography>
      <Box mt={6} />
      <InputLabel>{"Attachments (optional)"}</InputLabel>
      <Box mt={2}>
        <Box mb={1}>
          <InputLabel>{"Photos"}</InputLabel>
        </Box>
        <Box display={"flex"} flexWrap={"wrap"} mr={"-5px"}>
          {[...images, null].map((file, i) =>
            file === null ? (
              <FileUploader key={i} onUpload={updateFields} collection={path} />
            ) : (
              <FilePreview
                key={`${i}-${file.name}`}
                file={file.file!}
                name={file.name}
                collection={path}
                className={classes.image}
                onRemove={() => removeFile(file)}
              />
            )
          )}
        </Box>
      </Box>
      <Box mt={2} display={"flex"} flexDirection={"column"}>
        <InputLabel>{"Documents"}</InputLabel>
        <Box mt={1} display={"flex"} flexDirection={"column"}>
          {[...documents, null].map((file, i) =>
            file === null ? (
              <FileUploader
                key={i}
                imagesOnly={false}
                onUpload={updateFields}
                collection={path}
                children={(isLoaded) =>
                  isLoaded === false ? (
                    <CircularProgress size={24} />
                  ) : (
                    <Link component={"span"}>{"Upload document"}</Link>
                  )
                }
              />
            ) : (
              <FilePreview
                key={`${i}-${file.name}`}
                file={file.file!}
                name={file.name}
                collection={path}
                className={classes.document}
                onRemove={() => removeFile(file)}
              />
            )
          )}
        </Box>
      </Box>
      <Box mt={4} />
      <Button type="submit" variant="contained" color={"primary"}>
        {"Done"}
      </Button>
      <Box mt={2} />
      <Button color="secondary" onClick={onCancel}>
        {"Back"}
      </Button>
    </Form>
  );
};
