import { useSelector } from "react-redux";
import {
  FirestoreState,
  SupportRequest,
  FirebaseError,
  RequestStatus,
  Data,
  Requester,
} from "utils/firebase";
import { useFirestoreConnect } from "react-redux-firebase";
import { useLoaded, useError } from "./helpers";
import { useMemo } from "react";
import { useAuth } from "./profile";

export const useSupportRequests = (
  strataId: string,
  status?: RequestStatus
): [SupportRequest[], boolean, FirebaseError | null | undefined] => {
  const location = useMemo(() => `strata/${strataId}/support_request`, [
    strataId,
  ]);

  const supportRequests = useSelector<FirestoreState, SupportRequest[]>(
    ({ firestore: { ordered } }) => ordered?.[location] || []
  );

  const query = useMemo(() => {
    const where: Data<any> = {};
    if (status) {
      where["where"] = ["status", "==", status];
    }
    return strataId
      ? [
          {
            collection: "strata",
            doc: strataId,
            subcollections: [
              {
                collection: "support_requests",
                orderBy: [["created_at", "desc"]],
                ...where,
              },
            ],
            storeAs: location,
          },
        ]
      : [];
  }, [strataId, location, status]);

  useFirestoreConnect(query as any);

  const isLoaded = useLoaded(location);
  const hasError = useError(location);

  return [supportRequests, isLoaded, hasError];
};

export const useSupportRequest = (
  strataId: string,
  id: string
): [SupportRequest, boolean] => {
  const storeAs = `strata/${strataId}/support_requests/${id}`;

  const supportRequest = useSelector<FirestoreState, SupportRequest>(
    ({ firestore: { data } }) => data?.[storeAs]
  );

  const isLoaded = useLoaded(storeAs);

  useFirestoreConnect([
    {
      collection: "strata",
      doc: strataId,
      subcollections: [
        {
          collection: "support_requests",
          doc: id,
        },
      ],
      storeAs,
    },
  ]);

  return [supportRequest, isLoaded];
};

export const useCreator = (record: { creator: Requester }) => {
  const [auth] = useAuth();

  return record?.creator?.ref.id === auth.uid;
};
