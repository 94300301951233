import React, { ReactNode, Fragment } from "react";
import { RequestStatus } from "utils/firebase";
import { useSupportRequests } from "hooks/support_request";
import {
  List,
  Card,
  CardContent,
  Typography,
  CardActionArea,
  Box,
  CircularProgress,
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import MessageIcon from "@material-ui/icons/Message";
import { formatDate } from "utils/helpers";
import { CreatorName } from "components/CreatorName";

interface SupportRequestPageProps {
  strataId: string;
  children: ReactNode;
  type: RequestStatus;
}

export const SupportRequestPage = ({
  strataId,
  children,
  type,
}: SupportRequestPageProps) => {
  const [supportRequests, isSupportRequestsLoaded] = useSupportRequests(
    strataId,
    type
  );

  return (
    <List>
      {!isSupportRequestsLoaded ? (
        <Box display={"flex"} justifyContent={"center"}>
          <CircularProgress />
        </Box>
      ) : supportRequests.length ? (
        supportRequests.map((request) => (
          <Fragment key={request.id}>
            <Card>
              <CardActionArea
                component={RouterLink}
                to={`/dashboard/${strataId}/request/${request.id}`}
              >
                <CardContent>
                  <Box display={"flex"} justifyContent={"space-between"}>
                    <Typography color="textSecondary" gutterBottom>
                      {formatDate(request.created_at.toDate())}
                    </Typography>
                    <Box display={"flex"}>
                      <MessageIcon color={"secondary"} />
                      <Typography color="textSecondary" gutterBottom>
                        {request.comments_count || "0"}
                      </Typography>
                    </Box>
                  </Box>
                  <Typography color="secondary" variant="h6" gutterBottom>
                    {request.title}
                  </Typography>
                  <CreatorName creator={request.creator} />
                </CardContent>
              </CardActionArea>
            </Card>
            <Box pb={3} />
          </Fragment>
        ))
      ) : (
        children
      )}
    </List>
  );
};
