import {
  useFirestore,
  ReduxFirestoreQuerySetting,
  useFirestoreConnect,
} from "react-redux-firebase";
import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { FirestoreState, Data } from "utils/firebase";
import { last, isEqual, queryPathToString } from "utils/helpers";

type UpdateResult<T> = [T | null, (data: Object) => void, boolean | undefined];

interface Document extends Data<any> {
  id: string;
}

export const useUpdate = <T extends Document>(
  docPath: ReduxFirestoreQuerySetting
): UpdateResult<T> => {
  const [loading, setLoading] = useState<boolean>();

  const firestore = useFirestore();

  useFirestoreConnect(docPath);

  const document = useSelector<FirestoreState, any>(
    ({ firestore: { data } }) => {
      const {
        storeAs,
        doc,
        collection,
      } = docPath as ReduxFirestoreQuerySetting;
      const id = last(storeAs?.split("/"));

      const record = storeAs ? data[storeAs!] : data[collection!][doc!];

      return (
        !loading &&
        record && {
          id,
          ...record,
        }
      );
    },
    isEqual
  );

  const update = useCallback(
    async (data: Object) => {
      setLoading(true);
      const ref = firestore.doc(queryPathToString(docPath));
      const doc: { exists: boolean } = (await ref.get()) as any;
      if (doc.exists) {
        await firestore.update(docPath, data);
      } else {
        await firestore.set(docPath, data);
      }
      setLoading(false);
    },
    [firestore, docPath]
  );

  return [document, update, loading];
};
