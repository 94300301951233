import React, { ReactNode, useState, useCallback, useMemo } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Box,
  makeStyles,
  SwipeableDrawer,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar as MaterialAvatar,
  Link,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { Link as RouterLink, useLocation } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import GroupIcon from "@material-ui/icons/Group";
import { Container } from "components/Container";
import { Avatar } from "components/Avatar";
import { useAppBar } from "hooks/app_bar";
import { useUserStrata } from "hooks/strata";
import { Redirect, useRouteMatch } from "react-router-dom";
import { useUser } from "hooks/user";
import { useFirebase } from "react-redux-firebase";

interface MainPageProps {
  children: ReactNode;
}

const useStyles = makeStyles((theme) => ({
  offset: theme.mixins.toolbar,
  logo: {
    marginTop: theme.spacing(2),
    width: 250,
    selfAlign: "center",
    height: theme.spacing(5),
    "& img": {
      objectFit: "contain",
    },
  },
  addIcon: {
    marginRight: theme.spacing(2),
  },
  link: {
    color: theme.palette.primary.main,
  },
  title: {
    flexGrow: 1,
  },
  buttonHolder: {
    position: "absolute",
    left: "50%",
    bottom: "20px",
    transform: "translateX(-50%)",
  },
  buttonWrap: {
    position: "relative",
    padding: "50px 0",
  },
}));

const MainPage = ({ children }: MainPageProps) => {
  const classes = useStyles();
  const location = useLocation();
  const [profile] = useUser();
  const [title, visible, profileVisible] = useAppBar();

  const [isOpen, setOpen] = useState(false);
  const toggleDrawer = useCallback(
    (state) => () => {
      setOpen(state);
    },
    []
  );

  const firebase = useFirebase();

  const [strata, isLoaded] = useUserStrata();

  const match = useRouteMatch(`/dashboard/:id?`);
  const single_match = useRouteMatch(`/dashboard`);
  const strataId = useMemo(() => {
    const strataId = (match?.params as any)?.id;

    if (isLoaded) {
      if (!strata.find(({ id }) => id === strataId)) {
        return null;
      }
      return strataId;
    }
    return null;
  }, [isLoaded, strata, match]);

  const logout = useCallback(
    (e) => {
      e.preventDefault();

      firebase.logout();
    },
    [firebase]
  );

  return (
    <>
      {isLoaded &&
        (Boolean(match) || Boolean(single_match)) &&
        !Boolean(strataId) && (
          <Redirect to={`/dashboard/${strata?.[0]?.id || ""}`} />
        )}
      <SwipeableDrawer
        anchor={"left"}
        open={isOpen}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        <MaterialAvatar
          className={classes.logo}
          alt="Hello Strata"
          variant="square"
          src="/logo-horizontal.png"
        />
        <List>
          <ListItem>
            <ListItemAvatar>
              <Avatar src={profile?.image} />
            </ListItemAvatar>
            <ListItemText
              primary={`${profile?.first_name} ${profile?.last_name}`}
              secondary={
                <>
                  {profile?.email}
                  <br />
                  <Link
                    component={RouterLink}
                    to={`/profile?redirect=${location.pathname}`}
                    onClick={toggleDrawer(false)}
                  >
                    {"Edit profile"}
                  </Link>
                </>
              }
            />
          </ListItem>
        </List>
        <Divider />
        <List>
          {strata &&
            strata.map(({ id, address }) => (
              <ListItem
                component={RouterLink}
                to={`/dashboard/${id}`}
                selected={strataId === id}
                button
                onClick={toggleDrawer(false)}
                key={id}
              >
                <ListItemText primary={address} />
              </ListItem>
            ))}
          <ListItem
            button
            component={RouterLink}
            to={`/new_strata`}
            onClick={toggleDrawer(false)}
          >
            <AddCircleIcon className={classes.addIcon} color={"primary"} />
            <ListItemText
              className={classes.link}
              primary={"Add a strata plan"}
            />
          </ListItem>
        </List>
        <div className={classes.buttonWrap}>
          <div className={classes.buttonHolder}>
            <Link href="#" onClick={logout}>
              {"Sign out"}
            </Link>
          </div>
        </div>
      </SwipeableDrawer>
      <Box display={"flex"} flex={1} flexDirection={"column"}>
        {visible && title && (
          <>
            <AppBar position="fixed">
              <Toolbar>
                <IconButton
                  onClick={toggleDrawer(true)}
                  edge="start"
                  color="secondary"
                  aria-label="menu"
                >
                  <MenuIcon />
                </IconButton>
                <Typography className={classes.title} noWrap variant="h6">
                  {title === "Loading..." ? (
                    <Skeleton width={300} animation="wave" />
                  ) : (
                    title
                  )}
                </Typography>
                {profileVisible && (
                  <IconButton
                    component={RouterLink}
                    to={`/members/${strataId}`}
                    edge="end"
                    color="secondary"
                    aria-label="members"
                  >
                    <GroupIcon />
                  </IconButton>
                )}
              </Toolbar>
            </AppBar>
            <Box className={classes.offset} />
          </>
        )}
        <Container>{children}</Container>
      </Box>
    </>
  );
};

export default MainPage;
