import React, { useCallback } from "react";
import { Box, Typography, IconButton } from "@material-ui/core";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import GridOnIcon from "@material-ui/icons/GridOn";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import CancelIcon from "@material-ui/icons/Cancel";
import { classnames } from "utils/helpers";
import { useStyles } from "./FilePreview";
import { DeleteButton } from "./DeleteButton";
import { useRemoveFirebaseFile } from "hooks/image";

const icons: { [key: string]: any } = {
  "application/msword": InsertDriveFileIcon,
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": InsertDriveFileIcon,
  "application/pdf": PictureAsPdfIcon,
  "application/vnd.ms-excel": GridOnIcon,
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": GridOnIcon,
};

interface RemoteFilePreviewProps {
  className?: string;
  url?: string;
  path?: string;
  file?: File;
  type: string;
  name: string;
  onRemove: () => void;
}

export const RemoteFilePreview = ({
  className,
  url,
  path,
  type,
  file,
  name,
  onRemove,
}: RemoteFilePreviewProps) => {
  const classes = useStyles();

  console.log(path);
  const [remove] = useRemoveFirebaseFile(path!);

  const handleRemove = useCallback(() => {
    remove();
    onRemove();
  }, [onRemove, remove]);

  const Icon = type ? icons[type] || InsertDriveFileIcon : InsertDriveFileIcon;

  const isImage = Boolean(/^image\/.*$/.test(type));

  return (
    <Box className={classnames(classes.root, className)}>
      <Box className={classes.button}>
        {isImage ? (
          <div
            className={classes.image}
            style={{ backgroundImage: `url("${url}")` }}
          />
        ) : (
          <Icon fontSize={"large"} />
        )}
      </Box>
      {!isImage && (
        <Box pl={2} overflow={"hidden"} pr={3}>
          <Typography
            component={Box}
            overflow={"hidden"}
            textOverflow={"ellipsis"}
          >
            {name}
          </Typography>
        </Box>
      )}
      <DeleteButton
        onDelete={handleRemove}
        message={"Permanently delete this photo or document?"}
      >
        <IconButton className={classes.remove}>
          <CancelIcon className={isImage ? classes.icon : ""} />
        </IconButton>
      </DeleteButton>
    </Box>
  );
};
