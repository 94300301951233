import React from "react";
import { Typography, Box, TextField } from "@material-ui/core";
import { ROLES } from "utils/helpers";
import { StrataMemberRoles } from "utils/firebase";

interface UserStrataItemProps {
  address: string;
  role: StrataMemberRoles;
  value: string;
  onChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
}

export const UserStrataItem = ({
  address,
  role,
  value,
  onChange
}: UserStrataItemProps) => {
  return (
    <>
      <Typography>{address}</Typography>
      <Typography>{`Role: ${ROLES[role]}`}</Typography>
      <Box mt={2} />
      <TextField
        label={`Lot/unit number`}
        value={value}
        onChange={onChange}
        variant="outlined"
        helperText="If you own multiple, please only note 1 lot."
      />
      <Box mt={3} />
    </>
  );
};
