import React from "react";
import { Typography, Box } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { FormattedTypography } from "./FormatedTypography";

interface SupportRequestDetailsProps {
  details: string;
  isLoaded: boolean;
}

export const SupportRequestDetails = ({
  details,
  isLoaded,
}: SupportRequestDetailsProps) => {
  return isLoaded ? (
    <FormattedTypography>{details}</FormattedTypography>
  ) : (
    <Box>
      <Typography>
        <Skeleton width={200} animation="wave" />
      </Typography>
      <Typography>
        <Skeleton width={200} animation="wave" />
      </Typography>
      <Typography>
        <Skeleton width={70} animation="wave" />
      </Typography>
    </Box>
  );
};
