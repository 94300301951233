import React, { useCallback, useEffect } from "react";
import { AbsoluteContainer, Container } from "components/Container";
import { NavigationBar } from "components/NavigationBar";
import { useQuery } from "hooks/helpers";
import Skeleton from "@material-ui/lab/Skeleton";
import { useSupportRequest, useCreator } from "hooks/support_request";
import { useParams, Redirect, Link as RouterLink } from "react-router-dom";
import {
  Box,
  Typography,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
  TextField,
  Button,
} from "@material-ui/core";
import { useAppBar } from "hooks/app_bar";
import { Form } from "components/Form";
import {
  RequestStatus,
  SupportRequest,
  SupportRequestData,
  FieldValue,
} from "utils/firebase";
import { useForm } from "hooks/form";
import { Avatar } from "components/Avatar";
import { CreatorName } from "components/CreatorName";
import { SupportRequestDetails } from "components/SupportRequestDetails";
import { LoadingButton } from "components/LoadingButton";
import { useUpdate } from "hooks/update";
import { DeleteButton } from "components/DeleteButton";
import { useDelete } from "hooks/delete";
import { RequestDocumentsEdit } from "components/RequestDocumentsEdit";
import { useFirestore } from "react-redux-firebase";
import { useAuth } from "hooks/profile";

const INIT_DATA = {
  status: RequestStatus.Todo,
  title: "",
  details: "",
};

const REQUEST_VALIDATORS = {
  title: true,
  details: false,
};

const RequestEditPage = () => {
  const query = useQuery();
  useAppBar(undefined, false);
  const { id, strata_id } = useParams<{ id: string; strata_id: string }>();
  const path = `strata/${strata_id}/support_requests/${id}`;

  const firestore = useFirestore();

  const [auth] = useAuth();

  const [request, isLoaded] = useSupportRequest(strata_id, id);

  const isCreator = useCreator(request);

  const [, update, isLoading] = useUpdate<SupportRequest>({
    collection: "strata",
    doc: strata_id,
    subcollections: [
      {
        collection: "support_requests",
        doc: id,
      },
    ],
    storeAs: path,
  });

  const handleSubmit = useCallback(
    (data: any) => {
      update({
        ...data,
        edited_by: firestore.doc(`/users/${auth.uid}`),
        updated_at: FieldValue.serverTimestamp(),
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [update]
  );

  const [data, errors, handleChange, onSubmit, updateChange] = useForm<
    SupportRequestData
  >(INIT_DATA, handleSubmit, REQUEST_VALIDATORS);

  useEffect(() => {
    if (isLoaded === true && request) {
      updateChange({
        title: request.title,
        details: request.details,
        status: request.status,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request, isLoaded]);

  const [isDeleteLoading, remove] = useDelete(path);

  const deleteRequest = useCallback(() => {
    remove();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AbsoluteContainer>
      {isLoading === false && (
        <Redirect to={`/dashboard/${strata_id}/request/${id}`} />
      )}
      {isDeleteLoading === false && <Redirect to={`/dashboard/${strata_id}`} />}
      <NavigationBar
        title={"Edit request"}
        backTo={
          query.get("redirect") || `/dashboard/${strata_id}/request/${id}`
        }
      />
      <Container width={["100%", 600]}>
        <Form onSubmit={onSubmit}>
          <FormControl
            error={Boolean(errors.status)}
            required={true}
            component="fieldset"
          >
            <FormLabel component="legend">Status</FormLabel>
            <RadioGroup
              row={true}
              aria-label="gender"
              name="gender1"
              value={data.status}
              onChange={handleChange("status")}
            >
              <FormControlLabel
                value={RequestStatus.Todo}
                control={<Radio />}
                label="To do"
              />
              <FormControlLabel
                value={RequestStatus.Doing}
                control={<Radio />}
                label="Doing"
              />
              <FormControlLabel
                value={RequestStatus.Done}
                control={<Radio />}
                label="Done"
              />
              <FormControlLabel
                value={RequestStatus.OnHold}
                control={<Radio />}
                label="On hold"
              />
            </RadioGroup>
            {errors.status && <FormHelperText>{errors.status}</FormHelperText>}
          </FormControl>
          <Box pt={2} />
          {!isCreator && (
            <Typography color="secondary" variant="h6">
              {isLoaded ? (
                request?.title
              ) : (
                  <Skeleton width={300} animation="wave" />
                )}
            </Typography>
          )}

          {isCreator ? (
            <>
              <TextField
                label="Title"
                value={data.title}
                error={Boolean(errors.title)}
                required={true}
                inputProps={{
                  maxLength: 80,
                }}
                helperText={
                  errors.title ||
                  `${80 - (data.title?.length || 0)} characters remain`
                }
                variant="outlined"
                onChange={handleChange("title")}
              />
              <Box mt={2} />
              <TextField
                label="Details (optional)"
                value={data.details}
                error={Boolean(errors.details)}
                helperText={errors.details}
                variant="outlined"
                multiline
                rowsMax={8}
                onChange={handleChange("details")}
              />
            </>
          ) : (
              <Box display={"flex"} mt={2}>
                {isLoaded ? (
                  <Avatar src={request?.creator?.image} />
                ) : (
                    <Box mr={2}>
                      <Skeleton
                        animation="wave"
                        variant="circle"
                        width={56}
                        height={56}
                      />
                    </Box>
                  )}
                <Box>
                  <CreatorName creator={request?.creator!} gutterBottom />
                  <SupportRequestDetails
                    details={request?.details!}
                    isLoaded={isLoaded}
                  />
                  <Box mt={2} />
                  <Typography color={"secondary"}>
                    {"Only request creator can edit title/description"}
                  </Typography>
                </Box>
              </Box>
            )}
          <Box pt={3} />
          <RequestDocumentsEdit
            strataId={strata_id}
            requestId={id}
            attachments={request?.attachments || []}
            onChange={(data) => updateChange({ attachments: data.attachments })}
          />
          <Box pt={3} />
          <LoadingButton loading={Boolean(isLoading)}>
            {"Save changes"}
          </LoadingButton>
          <Box mt={2} />
          <Button
            color="secondary"
            component={RouterLink}
            to={
              query.get("redirect") || `/dashboard/${strata_id}/request/${id}`
            }
          >
            {"Cancel changes"}
          </Button>
          <Box mt={2} />
          {isCreator && (
            <DeleteButton
              onDelete={deleteRequest}
              title={"Do you need to delete this request?"}
              message={
                "Keep an audit trail by updating the status to 'done' or on 'hold' instead. Deleting a request cannot be undone."
              }
            />
          )}
          {!isCreator && (
            <Typography align={"center"} variant="body2">
              {"Only request creator can delete this request. You can notify the committee of required changes via a comment or update the status."}
            </Typography>
          )}
        </Form>
      </Container>
    </AbsoluteContainer>
  );
};

export default RequestEditPage;
