import { useFirestore } from "react-redux-firebase";
import { useCallback, useState, useMemo } from "react";
import { useAuth } from "./profile";
import { Nullable } from "./file";

export interface BatchRecord extends Object {
  path?: string;
  id: string;
  action?: "update" | "delete";
  [key: string]: any;
}

type CreateResult = [Nullable<boolean>, any, (records: BatchRecord[]) => void];

export const useBatchCreate = (
  docPath: string,
  includeCreator: boolean = true
): CreateResult => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState<Nullable<boolean>>(null);

  const firestore = useFirestore();

  const [auth] = useAuth();

  const creator = useMemo(
    () => ({
      email: auth.email,
      ref: firestore.doc(`/users/${auth.uid}`),
    }),
    [firestore, auth]
  );

  const create = useCallback(
    async (records: BatchRecord[]) => {
      setError(undefined);
      setLoading(true);
      const task: firebase.firestore.WriteBatch = records.reduce(
        (batch: firebase.firestore.WriteBatch, data) => {
          const { id, ...rec } = data;
          batch.set(firestore.collection(docPath).doc(id), {
            ...rec,
            ...(includeCreator ? { creator } : {}),
          });
          return batch;
        },
        firestore.batch()
      );
      try {
        await task.commit();
      } catch (e) {
        setError(e.message);
      }
      setLoading(false);
    },
    [firestore, includeCreator, creator, docPath, setLoading]
  );

  return [loading, error, create];
};

export const useBatchUpdate = (docPath?: string): CreateResult => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState<Nullable<boolean>>(null);

  const firestore = useFirestore();

  const update = useCallback(
    async (records: BatchRecord[]) => {
      setError(undefined);
      setLoading(true);

      const recordsWithRef = await Promise.all(
        records.map(async (data) => {
          const { id, path, action = "update", ...rec } = data;

          let exists = true;

          const ref = docPath
            ? firestore.collection(docPath).doc(id)
            : firestore.doc(path!);

          if (action === "update" || action === "delete") {
            try {
              const doc = await ref.get();
              exists = doc.exists;
            } catch (e) {
              exists = false;
            }
          }

          return {
            ref,
            exists,
            action,
            rec,
          };
        })
      );

      const task: firebase.firestore.WriteBatch = recordsWithRef
        .filter((r) => r.exists)
        .reduce((batch: firebase.firestore.WriteBatch, data) => {
          const { ref, action = "update", rec } = data;

          if (action === "delete") {
            batch[action](ref);
          } else {
            batch[action](ref, rec);
          }

          return batch;
        }, firestore.batch());
      try {
        await task.commit();
      } catch (e) {
        console.log("error", e);
        setError(e.message);
      }
      setLoading(false);
    },
    [firestore, docPath, setLoading]
  );

  return [loading, error, update];
};
