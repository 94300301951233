import React, { useCallback } from "react";
import { Box, TextField, Button, Typography } from "@material-ui/core";
import { Form } from "components/Form";
import { minLength, useForm } from "hooks/form";

interface CreateProfileNameParams {
  onChange?: (firstName: string, lastName: string) => void;
  onCancel?: () => void;
}

interface UserNames {
  first_name: string;
  last_name: string;
}

const INIT_DATA = {
  first_name: "",
  last_name: "",
};

const VALIDATORS = {
  first_name: minLength(2, "First name"),
  last_name: minLength(2, "Surname"),
};

export const CreateProfileName = ({
  onChange,
  onCancel,
}: CreateProfileNameParams) => {
  const handleSubmit = useCallback(
    (data: UserNames) => {
      onChange?.(data.first_name.toString().trim(), data.last_name.toString().trim());
    },
    [onChange]
  );

  const [names, errors, handleChange, onSubmit] = useForm<UserNames>(
    INIT_DATA,
    handleSubmit,
    VALIDATORS
  );

  return (
    <Form onSubmit={onSubmit}>
      <Typography align={"center"} variant="body2">
        {"Welcome! As it's your first time here, we'll create an account"}
      </Typography>
      <Box mt={4} />
      <TextField
        label="First name"
        autoFocus
        error={Boolean(errors.first_name)}
        helperText={errors.first_name}
        value={names.first_name}
        onChange={handleChange("first_name")}
        variant="outlined"
      />
      <Box mt={2} />
      <TextField
        label="Surname"
        value={names.last_name}
        error={Boolean(errors.last_name)}
        helperText={errors.last_name}
        onChange={handleChange("last_name")}
        variant="outlined"
      />
      <Box mt={2} />
      <Button variant="contained" color="primary" type="submit">
        {"Next"}
      </Button>
      <Box mt={2} />
      <Button color="secondary" onClick={() => onCancel?.()}>{"Cancel"}</Button>
    </Form>
  );
};
