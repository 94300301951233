import { useMemo } from "react";
import { useSelector } from "react-redux";
import {
  FirestoreState,
  SupportRequestComment,
  FirebaseError,
} from "utils/firebase";
import { useFirestoreConnect } from "react-redux-firebase";
import { useLoaded, useError } from "./helpers";

export const useComments = (
  strataId: string,
  supportRequestId: string
): [SupportRequestComment[], boolean, FirebaseError | null | undefined] => {
  const location = useMemo(
    () => `strata/${strataId}/support_requests/${supportRequestId}/comments`,
    [strataId, supportRequestId]
  );

  const comments = useSelector<FirestoreState, SupportRequestComment[]>(
    ({ firestore: { ordered } }) => ordered?.[location] || []
  );

  const query = useMemo(() => {
    return strataId
      ? [
        {
          collection: "strata",
          doc: strataId,
          subcollections: [
            {
              collection: "support_requests",
              doc: supportRequestId,
            },
            {
              collection: "comments",
              orderBy: [["created_at", "asc"]],
            },
          ],
          storeAs: location,
        },
      ]
      : [];
  }, [strataId, supportRequestId, location]);

  useFirestoreConnect(query as any);

  const isLoaded = useLoaded(location);
  const hasError = useError(location);

  return [comments, isLoaded, hasError];
};
