import { combineReducers, createStore } from "redux";
import firebase from "firebase/app";
//import * as firebase from "@firebase/testing";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";
import { FIREBASE } from "utils/config";
import { firebaseReducer } from "react-redux-firebase";
import { createFirestoreInstance, firestoreReducer } from "redux-firestore";

// react-redux-firebase config
const config = {
  userProfile: "users",
  autoPopulateProfile: false,
  useFirestoreForProfile: true,
};

export const FieldValue = firebase.firestore.FieldValue;

// Initialize firebase instance
firebase.initializeApp(FIREBASE);
//firebase.initializeTestApp({ projectId: "hello-strata-dev" });

// Initialize other services on firebase instance
firebase.firestore(); // <- needed if using firestore

/* //This is used for local testing only
let db = firebase.firestore(); // <- needed if using firestore
db.settings({
  host: "localhost:8080",
  ssl: false,
});
firebase.functions().useFunctionsEmulator("http://localhost:5001");
*/

// Add firebase to reducers
const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer, // <- needed if using firestore
});

// Create store with reducers and initial state
const initialState = {};
export const store = createStore(
  rootReducer,
  initialState,
  (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
  (window as any).__REDUX_DEVTOOLS_EXTENSION__()
);

export const props = {
  firebase,
  config,
  dispatch: store.dispatch,
  createFirestoreInstance, // <- needed if using firestore
};

export const getCredential = (email: string, password: string) =>
  firebase.auth.EmailAuthProvider.credential(email, password);

//interfaces
export interface Auth {
  uid: string;
  email: string;
  lastLoginAt: Date;
  createdAt: Date;
  providerData: Array<any>;
}

export interface FirebaseObject {
  profile: User;
  auth: Auth;
}

export interface FirebaseState {
  firebase: FirebaseObject;
}

export interface User {
  email: string;
  first_name: string;
  last_name: string;
  image?: string;
}

export interface Member {
  id: string;
  email: string;
  role: string;
  lot: string;
}

export interface Creator {
  ref: firebase.firestore.DocumentReference;
  email: string;
}

export enum StrataRoles {
  Admin = "admin",
  Creator = "creator",
  Member = "member",
}

export enum RequestStatus {
  Todo = "todo",
  Doing = "doing",
  Done = "done",
  OnHold = "on_hold",
}

export const StatusLabels: { [key in RequestStatus]: string } = {
  [RequestStatus.Todo]: "to do",
  [RequestStatus.Doing]: "doing",
  [RequestStatus.Done]: "done",
  [RequestStatus.OnHold]: "on hold",
};

export interface StrataPlan {
  str_number: string;
  str_name: string;
  suburb: string;
  post_code: string;
  state: string;
  creator: Creator;
  roles: { [key: string]: StrataRoles };
}

export interface UserStrataPlan {
  id?: string;
  address: string;
  role: StrataMemberRoles;
  lot?: string;
}

export interface Requester {
  ref: firebase.firestore.DocumentReference;
  lot?: string;
  name: string;
  image?: string;
  email?: string;
  role: StrataMemberRoles;
}

export interface SupportRequest {
  id: string;
  comments_count?: number;
  read_count?: number;
  creator: Requester;
  attachments: string[];
  title: string;
  details: string;
  status: RequestStatus;
  created_at: firebase.firestore.Timestamp;
  updated_at: firebase.firestore.Timestamp;
}

export interface SupportRequestData {
  title: string;
  details: string;
  status: RequestStatus;
  attachments?: string[];
}

export interface SupportRequestComment {
  id?: string;
  creator: Requester;
  message: string;
  created_at: firebase.firestore.Timestamp;
}

export enum StrataMemberRoles {
  CommitteeMember = "committee_member",
  Chairperson = "chairperson",
  Treasurer = "treasurer",
  Secretary = "secretary",
  BuildingManager = "building_manager",
  StrataManager = "strata_manager",
}

export interface ExternalInvite {
  completed?: boolean;
  strata: Data<{
    address: string;
    role: StrataMemberRoles;
  }>;
}

export type Data<T extends any> = { [key: string]: T };

export interface Status {
  requesting: Data<boolean>;
  requested: Data<boolean>;
}

export interface FirebaseError {
  code: string;
  name: string;
}

export interface Errors {
  byQuery: Data<FirebaseError>;
}

type FirestoreData = {
  [key: string]: any;
  users: Data<User>;
  strata: Data<StrataPlan>;
  external_invites: Data<ExternalInvite>;
};

type FirestoreOrdered = {
  [key: string]: any;
  users: User[];
  strata: StrataPlan[];
  external_invites: ExternalInvite[];
};

export interface FirestoreState {
  firestore: {
    status: Status;
    errors: Errors;
    data: FirestoreData;
    ordered: FirestoreOrdered;
  };
}

export interface StrataMember {
  id: string;
  email: string;
  name: string;
  role: string;
  lot: string;
  image: string;
  active: boolean;
  admin: boolean;
  creator: boolean;
}

export interface StrataInvite {
  id: string;
  email: string;
  role: string;
  completed: boolean;
}
