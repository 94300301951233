import { useFirestore } from "react-redux-firebase";
import { useCallback, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { FirestoreState, Data, FieldValue } from "utils/firebase";
import { get } from "utils/helpers";
import { useAuth } from "./profile";
import { Nullable } from "./file";

type CreateResult = [
  Document | null,
  (data: Object) => void,
  Nullable<boolean>,
  firebase.firestore.DocumentReference | null
];

interface DocResult {
  id: string;
  path: string;
}

interface Document extends Data<any> {
  id: string;
}

export const useCreate = (
  docPath: string,
  includeCreator: boolean = true,
  prepareDoc: boolean = false
): CreateResult => {
  const [doc, setDoc] = useState<DocResult | null>(null);
  const [loading, setLoading] = useState<Nullable<boolean>>(null);

  const firestore = useFirestore();

  const [auth] = useAuth();

  let _document = useMemo(
    () => (prepareDoc ? firestore.collection(docPath).doc() : null),
    [prepareDoc, firestore, docPath]
  );

  //useFirestoreConnect({ ...docPath, doc: doc?.id });

  const document = useSelector<FirestoreState, Document | null>(
    ({ firestore: { data } }) => {
      return doc && { id: doc!.id, ...get(data, doc!.path) };
    }
  );

  const creator = useMemo(
    () => ({
      email: auth.email,
      ref: firestore.doc(`/users/${auth.uid}`),
    }),
    [firestore, auth]
  );

  const create = useCallback(
    async (data: Object) => {
      setLoading(true);
      let result;
      if (_document) {
        result = await _document.set({
          ...data,
          created_at: FieldValue.serverTimestamp(),
          ...(includeCreator ? { creator } : {}),
        });
      } else {
        result = await firestore.add(docPath, {
          ...data,
          created_at: FieldValue.serverTimestamp(),
          ...(includeCreator ? { creator } : {}),
        });
      }
      setLoading(false);
      setDoc(result as any);
    },
    [firestore, includeCreator, creator, docPath, _document]
  );

  return [document, create, loading, _document];
};
