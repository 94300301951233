import React, { useState, useCallback, Fragment } from "react";
import { Box, TextField, Button, Typography } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import { Form } from "components/Form";
import { Container } from "components/Container";
import { useDismissibleError } from "hooks/error";

import firebase from "firebase/app";
//import * as firebase from "@firebase/testing";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";

interface LoginWithPasswordParams {
  error: string | null;
  onChange?: (password: string, saveLogin: boolean) => void;
  onCancel?: () => void;
  email?: string | null;
  isFB?: any;
  pendingCred?: any;
  methods?: string[] | null;
}

const methodNames: { [key: string]: string } = {
  "google.com": "Google",
  "facebook.com": "Facebook",
};

export const LoginWithPassword = ({
  error,
  onChange,
  onCancel,
  email,
  isFB,
  pendingCred,
  methods = null,
}: LoginWithPasswordParams) => {
  const [password, setPassword] = useState("");
  let history = useHistory();

  const [internalError] = useDismissibleError(password, error);

  const onSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      if (isFB && email) {
        firebase
          .auth()
          .signInWithEmailAndPassword(email, password)
          .then(function (result: any) {
            return result.user.linkWithCredential(pendingCred);
          });
      }
      event.preventDefault();
      onChange?.(password, true);
    },
    [email, isFB, onChange, password, pendingCred]
  );

  return (
    <Fragment>
      {methods?.includes("password") ? (
        <Form onSubmit={onSubmit}>
          <Typography align={"center"} variant='body2'>
            {"Welcome back! Please enter your password."}
          </Typography>
          <Box mt={4} />
          <Box display='none'>
            <TextField
              className='hidden'
              name='username'
              type='text'
              value={email}
              autoComplete='username'
            />
          </Box>
          <TextField
            label='Password'
            type='password'
            error={Boolean(internalError)}
            helperText={internalError}
            autoFocus
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            variant='outlined'
            autoComplete='current-password'
          />
          <Box mt={2} />
          <Button variant='contained' type='submit' color='primary'>
            {"Sign in"}
          </Button>
          <Box mt={2} />
          <Button color='secondary' component={Link} to='/forgotten_password'>
            {"Forgot password"}
          </Button>
          <Box mt={2} />
          <Button color='secondary' onClick={onCancel}>
            {"Cancel"}
          </Button>
        </Form>
      ) : (
        <Container>
          <Fragment>
            <Typography align={"center"} variant='body2'>
              You've previously created an account using {methodNames[methods![0]]} that
              shares the same email address.
            </Typography>
            <Box mt={2} />
          </Fragment>
          <Fragment>
            <Typography align={"center"} variant='body2'>
              Please Login with your {methodNames[methods![0]]} account.
            </Typography>
            <Box mt={2} />
          </Fragment>

          <Button
            variant='contained'
            color={"primary"}
            onClick={() => {
              history.push("/");
            }}
          >
            Back
          </Button>
        </Container>
      )}
    </Fragment>
  );
};
