import React from "react";
import { useHistory, Redirect } from "react-router-dom";
import { Container, Box } from "@material-ui/core";
import { Logo } from "components/Logo";
import { useResetPassword, useAuth } from "hooks/profile";
import { CreatePassword } from "components/CreatePassword";
import { isEmpty } from "react-redux-firebase";
import { useQuery } from "hooks/helpers";

const ResetPasswordPage = () => {
  let query = useQuery();
  let history = useHistory();

  const [auth, loaded] = useAuth();
  const [loading, verify, error, resetPassword] = useResetPassword(
    query.get("oobCode")
  );

  return verify === false ? (
    <Redirect to={"/"} />
  ) : loaded && !isEmpty(auth) ? (
    <Redirect to="/dashboard" />
  ) : (
    <Container>
      <Logo />
      <Box mt={2} />
      <CreatePassword
        labels={["New password", "Re-enter new password"]}
        error={error}
        loading={Boolean(loading)}
        showTerms={false}
        onChangeText={"Reset password & Sign in"}
        onChange={resetPassword}
        onCancel={() => history.replace("/")}
      />
    </Container>
  );
};

export default ResetPasswordPage;
