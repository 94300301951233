import React from "react";
import { AbsoluteContainer, Container } from "components/Container";
import { NavigationBar } from "components/NavigationBar";
import { useQuery } from "hooks/helpers";
import Skeleton from "@material-ui/lab/Skeleton";
import { useSupportRequest } from "hooks/support_request";
import { useParams } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import { Box, Typography, IconButton } from "@material-ui/core";
import { formatDate } from "utils/helpers";
import { Avatar } from "components/Avatar";
import { Gallery } from "components/Gallery";
import { useAppBar } from "hooks/app_bar";
import { CommentsList } from "components/CommentsList";
import { SupportRequestDetails } from "components/SupportRequestDetails";
import { CreatorName } from "components/CreatorName";
import EditIcon from "@material-ui/icons/Edit";
import { StatusLabels } from "utils/firebase";
import { useStrata } from "hooks/strata";

const RequestPage = () => {
  const query = useQuery();
  useAppBar(undefined, false);
  const { id, strata_id } = useParams<{ id: string; strata_id: string }>();

  const [request, isLoaded] = useSupportRequest(strata_id, id);
  const [strata] = useStrata(strata_id!);

  return (
    <AbsoluteContainer>
      <NavigationBar
        title={
          isLoaded ? (
            `${strata?.str_number} ${strata?.str_name} ${strata?.suburb}`
          ) : (
            <Skeleton width={300} animation="wave" />
          )
        }
        backTo={query.get("redirect") || `/dashboard/${strata_id}`}
        action={
          <IconButton
            component={RouterLink}
            to={`${id}/edit`}
            edge="end"
            color="secondary"
            aria-label="menu"
          >
            <EditIcon />
          </IconButton>
        }
      />
      <Container width={["100%", 600]}>
        <Box width={"100%"} display={"Flex"} justifyContent={"space-between"}>
          <Typography color={"textSecondary"}>
            {isLoaded ? (
              formatDate(request?.created_at.toDate())
            ) : (
              <Skeleton width={60} animation="wave" />
            )}
          </Typography>
          <Typography color={"textSecondary"}>
            {isLoaded ? (
              StatusLabels[request?.status]
            ) : (
              <Skeleton width={50} animation="wave" />
            )}
          </Typography>
        </Box>
        <Box width={"100%"} mt={1}>
          <Typography color="secondary" variant="h6">
            {isLoaded ? (
              request?.title
            ) : (
              <Skeleton width={300} animation="wave" />
            )}
          </Typography>
          <Box display={"flex"} mt={2}>
            {isLoaded ? (
              <Avatar src={request?.creator?.image} />
            ) : (
              <Box mr={2}>
                <Skeleton
                  animation="wave"
                  variant="circle"
                  width={56}
                  height={56}
                />
              </Box>
            )}
            <Box minWidth="0">
              <CreatorName creator={request?.creator!} gutterBottom />
              <SupportRequestDetails
                details={request?.details!}
                isLoaded={isLoaded}
              />
              <Box mt={1} />
              <Gallery attachments={request?.attachments} />
            </Box>
          </Box>
        </Box>
        <CommentsList
          strataId={strata_id}
          supportRequestId={id}
          count={request?.comments_count!}
        />
      </Container>
    </AbsoluteContainer>
  );
};

export default RequestPage;
