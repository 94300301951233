import React, { useCallback, useState } from "react";
import { Box, TextField, makeStyles, Button } from "@material-ui/core";
import { Avatar } from "./Avatar";
import { useUser } from "hooks/user";
import { useForm } from "hooks/form";
import { useCreate } from "hooks/create";
import { LoadingButton } from "./LoadingButton";

const useStyles = makeStyles((theme) => ({
  position: {
    zIndex: 10,
    position: "fixed",
    bottom: 0,
    left: "50%",
    transform: "translateX(-50%)",
    boxSizing: "border-box",
  },
  messageField: {
    flex: 1,
  },
}));

interface CommentData {
  message: string;
}

const INIT_DATA = {
  message: "",
};

interface PostCommentProps {
  strataId: string;
  supportRequestId: string;
  onFocus?: (visible: boolean) => void;
  hasCommented?: (visible: boolean) => void;
  hasCount?: (visible: number) => void;
  curCount: number;
}

export const PostComment = ({
  strataId,
  supportRequestId,
  onFocus,
  hasCommented,
  hasCount,
  curCount,
}: PostCommentProps) => {
  const classes = useStyles();
  const [user] = useUser();

  const [focused, setFocused] = useState(false);
  const [commented, setCommented] = useState(false);

  const [commentsCount, setCommentsCount] = useState(curCount);

  const [, create, isLoading] = useCreate(
    `/strata/${strataId}/support_requests/${supportRequestId}/comments`
  );

  const handleSubmit = useCallback(
    (data: CommentData) => {
      setFocused(false);
      setCommented(true);
      onFocus?.(false);
      hasCommented?.(true);
      hasCount?.(+ curCount + +1);
      setCommentsCount(+ curCount + +1);
      create(data);
      reset();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [create, onFocus, hasCommented, hasCount]
  );

  const [
    comment,
    errors,
    handleChange,
    onSubmit,
    updateChange,
    reset,
  ] = useForm<CommentData>(INIT_DATA, handleSubmit);

  const onBlur = useCallback(() => {
    setTimeout(() => {
      if (!comment.message) {
        setFocused(false);
        onFocus?.(false);
      }
    }, 100);
  }, [comment, onFocus]);

  const onCancel = useCallback(() => {
    updateChange({ message: "" });
    setFocused(false);
    onFocus?.(false);
  }, [onFocus, updateChange]);

  return (
    <Box
      component={"form"}
      p={2}
      display={"flex"}
      bgcolor={"#fff"}
      flexDirection={"column"}
      width={["100%", 600]}
      className={classes.position}
      onSubmit={onSubmit}
    >
      <Box display={"flex"}>
        <Avatar src={user?.image} />
        <TextField
          className={classes.messageField}
          onFocus={() => {
            setFocused(true);
            onFocus?.(true);
          }}
          onBlur={onBlur}
          error={Boolean(errors.message)}
          helperText={errors.message}
          value={comment.message}
          onChange={handleChange("message")}
          label="Add comment"
          variant="outlined"
          multiline
          rowsMax={8}
        />
        <Box mr={1} />
      </Box>
      {focused && (
        <Box
          display={"flex"}
          mt={2}
          justifyContent={"space-between"}
          flexDirection={["column", "row-reverse"]}
        >
          <LoadingButton loading={Boolean(isLoading)}>
            {"Add comment"}
          </LoadingButton>
          <Box mt={2} />
          <Button color="secondary" onClick={onCancel}>{"Cancel"}</Button>
        </Box>
      )}
    </Box>
  );
};
