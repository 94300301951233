import { useFirebase } from "react-redux-firebase";
import { useEffect, useState, useRef, useCallback } from "react";
import { Data } from "utils/firebase";

type Nullable<T> = T | null;

interface ImageCache {
  url: string;
  expire: Date;
}

export interface FileCacheMeta {
  contentType?: string | null;
  name: string;
}

export interface FileCache {
  url: string;
  path: string;
  meta: FileCacheMeta;
  expire: Date;
}

const IMAGES: Data<ImageCache> = {};

const FILES: Data<FileCache> = {};

export const useFirebaseImage = (
  name: string
): [Nullable<string>, Nullable<boolean>] => {
  const mounted = useRef(false);
  const [imageURL, setImageURL] = useState<Nullable<string>>(null);
  const [isLoaded, setIsLoaded] = useState<Nullable<boolean>>(null);
  const firebase = useFirebase();

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, [mounted]);

  useEffect(() => {
    if (!name) {
      setImageURL("");
      return;
    }
    if (name.indexOf("http") === 0) {
      setImageURL(name);
      setIsLoaded(true);
      return;
    }
    setIsLoaded(false);
    if (IMAGES[name] && IMAGES[name].expire.getTime() > Date.now()) {
      setIsLoaded(true);
      setImageURL(IMAGES[name].url);
      return;
    }

    (async () => {
      const url = await firebase.storage().ref(name).getDownloadURL();

      IMAGES[name] = {
        url,
        expire: new Date(Date.now() + 10 * 60 * 1000),
      };

      if (mounted.current) {
        setIsLoaded(true);
        setImageURL(url);
      }
    })();
  }, [name, firebase]);

  return [imageURL, isLoaded];
};

export const useRemoveFirebaseFile = (
  name: string
): [
    () => void,
    Nullable<boolean>,
    React.Dispatch<React.SetStateAction<Nullable<boolean>>>
  ] => {
  const firebase = useFirebase();
  const [isLoaded, setIsLoaded] = useState<Nullable<boolean>>(null);

  const mounted = useRef<boolean>();

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  const remove = useCallback(async () => {
    if (!name) {
      return;
    }
    setIsLoaded(false);
    delete IMAGES[name];
    delete FILES[name];
    try {
      await firebase.storage().ref(name).delete();
    } catch (e) {
      console.log("Problem removing the image");
    }
    if (mounted.current) {
      setIsLoaded(true);
    }
  }, [firebase, name]);

  return [remove, isLoaded, setIsLoaded];
};

export const useFirebaseFiles = (paths?: string[]): [FileCache[], boolean] => {
  const firebase = useFirebase();
  const [files, setFiles] = useState<Array<FileCache>>([]);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, [mounted]);

  useEffect(() => {
    if (!paths || !paths.length) {
      setFiles([]);
      setIsLoaded(true);
      return;
    }

    setIsLoaded(false);

    (async () => {
      const files = await Promise.all(
        paths.map(async (path) => {
          const { contentType, name } = await firebase
            .storage()
            .ref(path)
            .getMetadata();

          if (FILES[path] && FILES[path].expire.getTime() > Date.now()) {
            //load from cache
            return FILES[path];
          }

          const url = await firebase.storage().ref(path).getDownloadURL();

          FILES[path] = {
            url,
            path,
            meta: { contentType, name },
            expire: new Date(Date.now() + 10 * 60 * 1000),
          };

          return FILES[path];
        })
      );

      if (mounted.current) {
        setFiles(files);
        setIsLoaded(true);
      }
    })();
  }, [paths, firebase]);

  return [files, isLoaded];
};
