export const STATES = [
  {
    name: "Australian Capital Territory",
    abbrev: "ACT",
    iso: "AU-ACT",
    postal: "ACT"
  },
  {
    name: "New South Wales",
    abbrev: "NSW",
    iso: "AU-NSW",
    postal: "NSW"
  },
  {
    name: "Northern Territory",
    abbrev: "NT",
    iso: "AU-NT",
    postal: "NT"
  },
  {
    name: "Queensland",
    abbrev: "QLD",
    iso: "AU-QLD",
    postal: "QLD"
  },
  {
    name: "South Australia",
    abbrev: "SA",
    iso: "AU-SA",
    postal: "SA"
  },
  {
    name: "Tasmania",
    abbrev: "Tas",
    iso: "AU-TAS",
    postal: "TAS"
  },
  {
    name: "Victoria",
    abbrev: "Vic",
    iso: "AU-VIC",
    postal: "VIC"
  },
  {
    name: "Western Australia",
    abbrev: "WA",
    iso: "AU-WA",
    postal: "WA"
  }
];

export const ROLES = [
  {
    name: "Committee Member",
    code: "committee_member"
  },
  {
    name: "Chairperson",
    code: "chairperson"
  },
  {
    name: "Treasurer",
    code: "treasurer"
  },
  {
    name: "Secretary",
    code: "secretary"
  },
  {
    name: "Building Manager",
    code: "building_manager"
  },
  {
    name: "Strata Manager",
    code: "strata_manager"
  }
];
