import { Data, StrataMemberRoles, Requester } from "./firebase";
import { ReduxFirestoreQuerySetting } from "react-redux-firebase";
import moment from "moment";

export const get = (data: Data<any>, path: string, delimiter: string = "/") => {
  const segments = path.split(delimiter);

  return segments.reduce((result, segment, index) => {
    return result[segment] || (index === segments.length - 1 ? undefined : {});
  }, data);
};

export const isFunction = (x: any) =>
  Object.prototype.toString.call(x) === "[object Function]";

export const last = <T>(data?: Array<T>): T | undefined =>
  data && data[data.length - 1];

export const isArrayEqual = (a: Array<any>, b: Array<any>) =>
  a.every((value, i) => value === b[i]);

export const isEqual = (a: Data<any>, b: Data<any>) => {
  // Create arrays of property names
  const aProps = Object.getOwnPropertyNames(a);
  const bProps = Object.getOwnPropertyNames(b);

  // If number of properties is different,
  // objects are not equivalent
  if (aProps.length !== bProps.length) {
    return false;
  }

  for (let i = 0; i < aProps.length; i++) {
    const propName = aProps[i];

    // If values of same property are not equal,
    // objects are not equivalent
    if (a[propName] !== b[propName]) {
      return false;
    }
  }

  // If we made it this far, objects
  // are considered equivalent
  return true;
};

export const clearUndefined = <T>(
  data: { [key: string]: any },
  replace?: { [key: string]: any }
) =>
  Object.keys(data).reduce((result, key) => {
    if (data[key] === undefined && replace?.[key] === undefined) {
      return result;
    }
    return {
      ...result,
      [key]: data[key] === undefined ? replace?.[key] : data[key],
    };
  }, {}) as T;

export const queryPathToString = (
  docPath: ReduxFirestoreQuerySetting
): string => {
  let segments = [];

  if (docPath.collection) {
    segments.push(docPath.collection);
  }
  if (docPath.doc) {
    segments.push(docPath.doc);

    if (docPath.subcollections && docPath.subcollections.length) {
      docPath.subcollections.forEach((col) => {
        segments.push(col.collection);
        if (col.doc) {
          segments.push(col.doc);
        }
      });
    }
  }

  return segments.join("/");
};

export const ROLES: { [key in StrataMemberRoles]: string } = {
  committee_member: "Committee Member",
  chairperson: "Chairperson",
  treasurer: "Treasurer",
  secretary: "Secretary",
  building_manager: "Building Manager",
  strata_manager: "Strata Manager",
};

export const createChangeEvent = <T>(value: any) => {
  return { target: { value } } as React.ChangeEvent<{ value: T }>;
};

export const isYou = (creator: Requester, user: { uid: string }): boolean =>
  creator.ref.id === user.uid;

export const memberDisplayName = (
  name: string,
  role: StrataMemberRoles,
  lot?: string
) => {
  const [firstName, lastName] = name.split(" ");
  return `${firstName} ${lastName[0]} (${lot ? `Lot ${lot}, ` : ""}${
    ROLES[role]
  })`;
};

export const creatorDisplayName = (creator: Requester, auth: { uid: string }) =>
  isYou(creator, auth)
    ? "You"
    : creator.name
    ? memberDisplayName(creator.name, creator.role, creator.lot)
    : null;

export const formatDate = (date: Date) => {
  return moment(date).calendar(undefined, {
    sameDay: "[Today] hh:mma",
    nextDay: "[Tomorrow] hh:mma",
    nextWeek: "D MMM",
    lastDay: "[Yesterday] hh:mma",
    lastWeek: "D MMM",
    sameElse: "D MMM",
  });
};

export const isEmpty = (data: { [key: string]: any }) =>
  Object.keys(data).every(
    (key) => data[key] === undefined || data[key] === null
  );

export const clearUndefinedOrNull = <T>(
  data: { [key: string]: any },
  replace?: { [key: string]: any }
) =>
  Object.keys(data).reduce((result, key) => {
    if (
      (data[key] === undefined || data[key] === null) &&
      replace?.[key] === undefined
    ) {
      return result;
    }
    return {
      ...result,
      [key]: data[key] || replace?.[key],
    };
  }, {}) as T;

export const classnames = (...classes: (string | undefined)[]) => {
  return classes.filter((cls) => cls).join(" ");
};

export interface GenericFile {
  name: string;
  type: string;
  path?: string;
  url?: string;
  file?: File;
}

export const fileToGeneric = (file: File) => {
  return new Promise<GenericFile>((resolve, reject) => {
    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        resolve({
          name: file.name,
          type: file.type,
          file: file,
          url: e.target?.result as string,
        });
      };

      reader.onerror = (e) => {
        reject(e);
      };

      reader.readAsDataURL(file);
    }
  });
};

export const getFileName = (name: string): string => {
  const parts = name.split(".");
  parts.pop();
  return parts.join(".");
};

export const increaseFileName = (name: string, index: number): string => {
  const parts = name.split(".");
  const ext = parts.pop();
  return `${parts.join(".")} (${index}).${ext}`;
};

export const changeFileName = (name: string, id?: string): string => {
  if (!id) {
    return name;
  }
  const ext = name.split(".").pop();

  return `${id}.${ext}`;
};

export const replaceSearch = (url: string, search: string | undefined) => {
  const [path] = url.split("?");

  if (search === undefined) {
    return url;
  }

  return [path, search].join("?");
};
