import React from "react";
import {
  Box,
  Tabs,
  Tab,
  makeStyles,
  Fab,
  Typography,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { useAppBar } from "hooks/app_bar";
import { useStrata } from "hooks/strata";
import { useTabs } from "hooks/tabs";
import {
  Link as RouterLink,
  useParams,
  useLocation,
} from "react-router-dom";
import { TabPanel } from "components/TabPanel";
import { SupportRequestPage } from "./support_requests";
import { RequestStatus } from "utils/firebase";

const useStyles = makeStyles((theme) => ({
  offset: theme.mixins.toolbar,
  tabsOffset: {
    height: 32,
  },
  tabs: {
    position: "fixed",
    marginBottom: 0,
    zIndex: 1,
    top: 0,
    left: 0,
    right: 0,
    background: "white",
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

const DashboardPage = () => {
  const classes = useStyles();
  const location = useLocation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const params = useParams<{ id: string }>();

  const strataId = params?.id;

  const [strata, isLoaded] = useStrata(strataId);

  useAppBar(
    !isLoaded
      ? `Loading...`
      : `${strata?.str_number} ${strata?.str_name} ${strata?.suburb}`,
    true,
    Boolean(strata)
  );

  const [tabIndex, handleChange] = useTabs();

  return (
    <Box minWidth={["100%", 600]}>
      <Box className={classes.tabs}>
        <Box className={classes.offset} />
        <Tabs
          value={tabIndex}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="secondary"
          centered={matches}
          variant={matches ? "standard" : "fullWidth"}
        >
          <Tab label="To Do" />
          <Tab label="Doing" />
          <Tab label="Done" />
          <Tab label="On hold" />
        </Tabs>
      </Box>
      <Box className={classes.tabsOffset} />
      <TabPanel value={tabIndex} index={0}>
        <SupportRequestPage strataId={strataId} type={RequestStatus.Todo}>
          <Box pt={4} />
          <Typography align={"center"} variant="body2">
            {"Hello!"}
          </Typography>
          <Box pt={2} />
          <Typography align={"center"} variant="body2">
            {
              "Select '+' below to add your strata plan's first support request."
            }
          </Typography>
        </SupportRequestPage>
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <SupportRequestPage strataId={strataId} type={RequestStatus.Doing}>
          <Box pt={4} />
          <Typography align={"center"} variant="body2">
            {"No support requests in progress yet."}
          </Typography>
        </SupportRequestPage>
      </TabPanel>
      <TabPanel value={tabIndex} index={2}>
        <SupportRequestPage strataId={strataId} type={RequestStatus.Done}>
          <Box pt={4} />
          <Typography align={"center"} variant="body2">
            {"No support requests completed yet."}
          </Typography>
        </SupportRequestPage>
      </TabPanel>
      <TabPanel value={tabIndex} index={3}>
        <SupportRequestPage strataId={strataId} type={RequestStatus.OnHold}>
          <Box pt={4} />
          <Typography align={"center"} variant="body2">
            {"No support requests on hold."}
          </Typography>
        </SupportRequestPage>
      </TabPanel>
      <Fab
        className={classes.fab}
        component={RouterLink}
        to={`/dashboard/${params.id}/add_request?redirect=${location.pathname}${location.search}`}
        size="large"
        color="primary"
        aria-label="add"
      >
        <AddIcon />
      </Fab>
    </Box>
  );
};

export default DashboardPage;
