import { useFirestoreConnect } from "react-redux-firebase";
import { useSelector } from "react-redux";
import {
  FirestoreState,
  StrataMember,
  StrataInvite,
  FirebaseError,
  StrataMemberRoles,
} from "utils/firebase";
import { useMemo } from "react";
import { useLoaded, useError } from "./helpers";

interface MemberItem {
  id: string;
  name: string;
  photo?: string;
  role: StrataMemberRoles;
  lot?: string;
  invite: boolean;
  admin: boolean;
}

export const useMembers = (
  id: string
): [MemberItem[], boolean, FirebaseError | null | undefined] => {
  useFirestoreConnect([
    {
      collection: "strata",
      doc: id,
      subcollections: [
        {
          collection: "members",
          where: ["active", "==", true],
        },
      ],
      storeAs: `strata/${id}/members`,
    },
  ]);

  useFirestoreConnect([
    {
      collection: "strata",
      doc: id,
      subcollections: [
        {
          collection: "invites",
          where: ["completed", "==", false],
        },
      ],
      storeAs: `strata/${id}/invites`,
    },
  ]);

  const members = useSelector<FirestoreState, StrataMember[]>(
    ({ firestore: { ordered } }) => ordered?.[`strata/${id}/members`]
  );

  const invites = useSelector<FirestoreState, StrataInvite[]>(
    ({ firestore: { ordered } }) => ordered?.[`strata/${id}/invites`]
  );

  const isLoadedInvites = useLoaded(`strata/${id}/invites`);
  const hasErrorInvites = useError(`strata/${id}/invites`);

  const isLoadedMembers = useLoaded(`strata/${id}/members`);
  const hasErrorMembers = useError(`strata/${id}/members`);

  const isLoaded = useMemo(() => isLoadedInvites && isLoadedMembers, [
    isLoadedInvites,
    isLoadedMembers,
  ]);

  const hasError = useMemo(() => hasErrorInvites || hasErrorMembers, [
    hasErrorInvites,
    hasErrorMembers,
  ]);

  const membersList: MemberItem[] = useMemo(
    () =>
      members &&
      invites &&
      [...members, ...invites].map((member) => {
        if ((member as StrataMember).name) {
          return {
            id: member.id,
            name: (member as StrataMember).name,
            role: (member as StrataMember).role as StrataMemberRoles,
            lot: (member as StrataMember).lot,
            admin: (member as StrataMember).admin,
            image: (member as StrataMember).image,
            invite: false,
          };
        }
        return {
          id: member.id,
          name: (member as StrataInvite).email,
          role: member.role as StrataMemberRoles,
          admin: false,
          invite: true,
        };
      }),
    [members, invites]
  );

  return [membersList, isLoaded, hasError];
};
