import { useFirestoreConnect } from "react-redux-firebase";
import { useSelector } from "react-redux";
import {
  FirestoreState,
  StrataPlan,
  UserStrataPlan,
  FirebaseError,
} from "utils/firebase";
import { useLoaded, useError, FirestoreRecord } from "hooks/helpers";
import { useAuth } from "./profile";
import { useMemo } from "react";

export const useStrata = (
  id: string
): FirestoreRecord<StrataPlan | undefined | null> => {
  const strata = useSelector<FirestoreState, StrataPlan>(
    ({ firestore: { data } }) => data?.strata?.[id]
  );

  useFirestoreConnect([{ collection: "strata", doc: id }]);

  const isLoaded = useLoaded(`strata/${id}`);
  const hasError = useError(`strata/${id}`);

  return [strata, isLoaded, hasError];
};

export const useCreator = (strata: StrataPlan) => {
  const [auth] = useAuth();

  return strata?.creator?.ref.id === auth.uid;
};

export const useUserStrata = (): [
  Array<UserStrataPlan>,
  boolean,
  FirebaseError | null | undefined
] => {
  const [auth] = useAuth();

  const location = useMemo(() => `users/${auth.uid}/strata`, [auth]);

  const strata = useSelector<FirestoreState, UserStrataPlan[]>(
    ({ firestore: { ordered } }) => ordered?.[location] || []
  );

  useFirestoreConnect([
    {
      collection: "users",
      doc: auth.uid,
      subcollections: [{ collection: "strata", where: ["active", "==", true] }],
      storeAs: location,
    },
  ]);

  const isLoaded = useLoaded(location);
  const hasError = useError(location);

  return [strata, isLoaded, hasError];
};
