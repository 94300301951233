import React, { useMemo, useCallback, useState } from "react";
import Carousel, { Modal, ModalGateway } from "react-images";
import { FileCache } from "hooks/image";
import { FilePreviewComponent } from "./FilePreviewComponent";
import { Box, ButtonBase, Typography, makeStyles } from "@material-ui/core";

interface GalleryPreviewProps {
  images: FileCache[];
}

const useStyles = makeStyles((theme) => ({
  space: {
    marginRight: 10,
  },
  button: {
    width: 80,
    height: 80,
    display: "flex",
    flexShrink: 0,
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    overflow: "hidden",
    borderRadius: 10,
    border: "1px solid #ccc",
  },
}));

export const GalleryPreview = ({ images }: GalleryPreviewProps) => {
  const classes = useStyles();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const toggleModal = useCallback(() => {
    setModalIsOpen((state) => !state);
  }, []);

  const openModal = useCallback(
    (i) => () => {
      setCurrentIndex(i);
      setModalIsOpen(true);
    },
    []
  );

  const views = useMemo(
    () =>
      images.map(({ url, meta }) => ({
        source: url,
        caption: meta.name,
      })),
    [images]
  );

  const preview = useMemo(() => {
    if (!images) {
      return images;
    }
    if (images.length > 3) {
      return images.slice(0, 2);
    }
    return images;
  }, [images]);

  return (
    <>
      <Box display={"flex"}>
        {preview?.map((image, i) => (
          <FilePreviewComponent
            className={classes.space}
            key={`${image.url}-${i}`}
            url={image.url}
            onClick={openModal(i)}
          />
        ))}
        {images?.length > 3 && (
          <ButtonBase className={classes.button} onClick={openModal(2)}>
            <Typography component="div">
              <Box component={"span"}>{images?.length}</Box>
              <Box>{"images"}</Box>
            </Typography>
          </ButtonBase>
        )}
      </Box>
      <ModalGateway>
        {modalIsOpen ? (
          <Modal onClose={toggleModal}>
            <Carousel currentIndex={currentIndex} views={views} />
          </Modal>
        ) : null}
      </ModalGateway>
    </>
  );
};
