import { useState, useCallback, useMemo, useEffect, useRef } from "react";
import { useBatchCreate, useBatchUpdate } from "./batch";
import { validEmail } from "./form";
import { ROLES } from "utils/data";
import { useAuth } from "./profile";
import { useFirestoreConnect } from "react-redux-firebase";
import { useSelector } from "react-redux";
import {
  FirestoreState,
  ExternalInvite,
  StrataMemberRoles,
} from "utils/firebase";
import { useLoaded } from "./helpers";
import { Nullable } from "./file";

interface Invite {
  id: string;
  email: string;
  role: string;
}

interface InviteError {
  id?: string;
  email?: string;
  role?: string;
}

const INIT_DATA = [
  {
    id: "",
    email: "",
    role: ROLES[0].code,
  },
];

type ChangeCallback = (
  index: number | string,
  name: string
) => (
  event: React.ChangeEvent<{
    value: unknown;
  }>
) => void;

export const useInviteMembers = (
  id: string
): [
  Invite[],
  InviteError[],
  Nullable<boolean>,
  any,
  ChangeCallback,
  (event: React.FormEvent<HTMLFormElement>) => void,
  (e: React.FormEvent<HTMLElement>) => void
] => {
  const [touched, setTouched] = useState(false);
  const [invites, setInvites] = useState<Invite[]>(INIT_DATA);

  const errors = useMemo(
    () =>
      invites.map((invite, index) => {
        if (!invite.email && index > 0) {
          return {};
        }
        const error = validEmail(
          invite.email,
          "Valid email address is required."
        );
        if (error) {
          return { email: error };
        }
        return {};
      }),
    [invites]
  );

  const [isLoading, error, create] = useBatchCreate(`/strata/${id}/invites`);

  const addInvite = useCallback((e: React.FormEvent<HTMLElement>) => {
    e.preventDefault();
    setInvites((prev) => {
      return [
        ...prev,
        {
          ...INIT_DATA[0],
        },
      ];
    });
  }, []);

  const handleChange = useCallback(
    (index: string | number, name: string) => (
      event: React.ChangeEvent<{ value: unknown }>
    ) => {
      if (touched) {
        setTouched(false);
      }
      const value = event.target.value;
      setInvites((prev) => {
        return prev.map((values, i) => {
          if (i === index) {
            return {
              ...values,
              [name]: value,
            };
          }
          return values;
        });
      });
    },
    [touched]
  );

  const onSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      setTouched(true);
      if (!errors.some((error) => error.email)) {
        create(
          invites
            .filter((invite) => invite.email)
            .map((invite) => ({
              ...invite,
              id: invite.email,
              completed: false,
            }))
        );
      }
    },
    [errors, create, invites]
  );

  return [
    invites,
    touched ? errors : [],
    isLoading,
    error,
    handleChange,
    onSubmit,
    addInvite,
  ];
};

interface ExternalInviteLot {
  id: string;
  role: StrataMemberRoles;
  strata: string;
  lot: string;
}

export const useExternalInvites = (): [
  ExternalInviteLot[],
  Nullable<ExternalInvite>,
  Nullable<boolean>,
  Nullable<boolean>,
  any,
  ChangeCallback,
  (event: React.FormEvent<HTMLFormElement>) => void
] => {
  const [auth] = useAuth();

  useFirestoreConnect([
    { collection: "external_invites", doc: auth?.email },
    {
      collection: "users",
      doc: auth?.uid,
      subcollections: [
        {
          collection: "strata",
          limit: 1,
        },
      ],
      storeAs: `users/${auth?.uid}/single_strata`,
    },
  ]);

  const invite = useSelector<FirestoreState, Nullable<ExternalInvite>>(
    ({ firestore: { data } }) => data?.external_invites?.[auth?.email]
  );

  const isLoaded = useLoaded(`external_invites/${auth?.email}`);
  const isLoadedRef = useRef<Nullable<boolean>>(null);

  const [externalInvites, setExternalInvites] = useState<ExternalInviteLot[]>(
    []
  );

  useEffect(() => {
    if (isLoaded && !isLoadedRef.current) {
      if (!invite || invite.completed) {
        return;
      }
      setExternalInvites(
        Object.keys(invite!.strata).map((id) => ({
          id,
          lot: "",
          role: invite!.strata[id].role || "committee_member",
          strata: invite!.strata[id].address,
        }))
      );
    }
    isLoadedRef.current = isLoaded;
  }, [isLoaded, invite]);

  const [isLoading, error, update] = useBatchUpdate();

  const handleChange = useCallback(
    (id: string | number, property: string) => (
      event: React.ChangeEvent<{ value: unknown }>
    ) => {
      const value = event.target.value as string;

      setExternalInvites((prev) =>
        prev.map((invite) =>
          invite.id === id ? { ...invite, [property]: value } : invite
        )
      );
    },
    []
  );

  const onSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const actions = externalInvites.map((invite) => ({
        id: auth.uid,
        path: `strata/${invite.id}/members/${auth.uid}`,
        lot: invite.lot,
      }));

      update([
        ...actions,
        {
          id: auth.email,
          path: `external_invites/${auth.email}`,
          completed: true,
        },
      ]);
    },
    [update, auth, externalInvites]
  );

  return [
    externalInvites,
    !invite || invite?.completed ? null : invite,
    !isLoaded,
    isLoading,
    error,
    handleChange,
    onSubmit,
  ];
};
