import { useFirestore, ReduxFirestoreQuerySetting } from "react-redux-firebase";
import { useCallback, useState } from "react";

export const useDelete = (
  docPath: ReduxFirestoreQuerySetting | string
): [boolean | undefined, () => void] => {
  const [loading, setLoading] = useState<boolean>();

  const firestore = useFirestore();

  const remove = useCallback(async () => {
    setLoading(true);
    await firestore.delete(docPath);
    setLoading(false);
  }, [firestore, docPath]);

  return [loading, remove];
};
