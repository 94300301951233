import React from "react";
import { makeStyles } from "@material-ui/core";

interface BackgroundImageProps {
  src?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
}));

export const BackgroundImage = ({ src }: BackgroundImageProps) => {
  const classes = useStyles();
  return (
    <div style={{ backgroundImage: `url("${src}")` }} className={classes.root} />
  );
};
