import { useFirestoreConnect } from "react-redux-firebase";
import { useAuth } from "./profile";
import { UserStrataPlan, FirestoreState } from "utils/firebase";
import { Nullable } from "./file";
import { useSelector } from "react-redux";
import { useLoaded } from "./helpers";

export const useSingleStrata = (): [UserStrataPlan, Nullable<boolean>] => {
  const [auth] = useAuth();

  useFirestoreConnect([
    {
      collection: "users",
      doc: auth?.uid,
      subcollections: [
        {
          collection: "strata",
          limit: 1,
        },
      ],
      storeAs: `users/${auth?.uid}/single_strata`,
    },
  ]);

  const strata = useSelector<FirestoreState, UserStrataPlan[]>(
    ({ firestore: { ordered } }: any) =>
      ordered?.[`users/${auth?.uid}/single_strata`] || []
  );

  const isLoaded = useLoaded(`users/${auth?.uid}/single_strata`);

  return [strata[0], isLoaded];
};
