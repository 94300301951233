import React, { ReactNode } from "react";
import { makeStyles, Box, Button, CircularProgress } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: "flex",
    position: "relative"
  },
  button: {
    flex: 1
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
}));

interface LoadingButtonProps {
  disabled?: boolean;
  loading: boolean;
  children: ReactNode;
}

export const LoadingButton = ({
  disabled = false,
  loading,
  children
}: LoadingButtonProps) => {
  const classes = useStyles();

  return (
    <Box mt={2} className={classes.wrapper}>
      <Button
        variant="contained"
        disabled={disabled || loading}
        className={classes.button}
        color="primary"
        type="submit"
      >
        {children}
      </Button>
      {loading && (
        <CircularProgress size={24} className={classes.buttonProgress} />
      )}
    </Box>
  );
};
