import React, { forwardRef } from "react";
import {
  Link,
  Typography,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction
} from "@material-ui/core";
import { Avatar } from "components/Avatar";
import { Link as RouterLink } from "react-router-dom";
import { ROLES } from "utils/helpers";
import { StrataMemberRoles } from "utils/firebase";

interface MemberListItemProps {
  strataId: string;
  key?: any;
  image?: string;
  name: string;
  role: StrataMemberRoles;
  invite: boolean;
  lot?: string;
  admin: boolean;
  current: boolean;
}

export const MemberListItem = forwardRef<HTMLLIElement, MemberListItemProps>(
  ({ strataId, image, name, role, invite, lot, admin, current }, ref) => (
    <ListItem ref={ref}>
      <ListItemAvatar>
        <Avatar src={image} />
      </ListItemAvatar>
      <ListItemText
        primary={name}
        secondary={
          <>
            {ROLES[role]}
            <br />
            {invite ? "Invite pending acceptance" : lot ? `Lot ${lot}` : ""}
          </>
        }
      />
      <ListItemSecondaryAction>
        <Typography variant="body2" align={"right"}>
          {admin && (
            <>
              {"Admin"}
              <br />
            </>
          )}
          {current && (
            <Link
              component={RouterLink}
              to={`/profile?redirect=/members/${strataId}`}
            >
              {"Edit profile"}
            </Link>
          )}
        </Typography>
      </ListItemSecondaryAction>
    </ListItem>
  )
);
