import { useFirebase } from "react-redux-firebase";
import { useEffect, useState, useRef, useCallback, useMemo } from "react";
import { GenericFile, increaseFileName, getFileName, fileToGeneric } from "utils/helpers";

export type Nullable<T> = T | null;

export const useFirebaseImageUpload = (
  bucked: string,
  id?: string,
  unique: boolean = false
): [
  (e: React.ChangeEvent<HTMLInputElement>) => void,
  Nullable<boolean>,
  Nullable<GenericFile>
] => {
  const mounted = useRef(false);
  const [isLoaded, setIsLoaded] = useState<Nullable<boolean>>(null);
  const [file, setFile] = useState<Nullable<GenericFile>>(null);
  const firebase = useFirebase();

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, [mounted]);

  const upload = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0];
      if (!file) {
        return;
      }
      const ext = file.name.split(".").pop();

      const gFile = await fileToGeneric(file);

      let count = 0;

      const fileName = getFileName(gFile.name);

      const bucket = firebase.storage().ref(bucked);
      try {
        const res = await bucket.listAll();
        res.items.forEach((itemRef: any) => {
          if (itemRef.name.indexOf(fileName) === 0) {
            if (itemRef.name === gFile.name && count === 0) {
              count = 1;
              return;
            }
            const [,index] = itemRef.name.match(/\((\d+)\)\./) || [];

            if (index && count <= Number(index)) {
              count = Number(index) + 1;
            }
          }
        });
        if (count) {
          gFile.name = increaseFileName(gFile.name, count);
        }
      } catch(e) {
      }

      setFile(gFile);
      setIsLoaded(false);

      try {
        await firebase
          .storage()
          .ref(bucked)
          .child(id ? `${id}.${ext}` : gFile.name)
          .put(file);
      } catch (e) {
        console.log("Problem uploading the image");
      }

      setIsLoaded(true);
    },
    [bucked, id, firebase]
  );

  return [upload, isLoaded, file];
};

export const useFileReader = (file: File | null) => {
  const [url, setUrl] = useState();
  const reader = useMemo(() => new FileReader(), []);

  useEffect(() => {
    const listener = (e: any) => {
      setUrl(e.target.result);
      reader.removeEventListener("load", listener);
    };
    reader.addEventListener("load", listener);
    return () => {
      reader.removeEventListener("load", listener);
    };
  });

  useEffect(() => {
    if (file && /^image\/.*$/.test(file.type)) {
      reader.readAsDataURL(file);
    }
  }, [reader, file]);

  return [url];
};
