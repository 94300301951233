import React from "react";
import { Provider } from "react-redux";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import Routes from "routes";
import FullStory from "react-fullstory";

import { store, props } from "utils/firebase";

const FullStoryWrapper =
  process.env.REACT_APP_ENV === "production" ? FullStory : (props: any) => null;

const ORG_ID = "NE856";

const primaryColor = "#F15929";
const secondaryColor = "#004477";
const typographyPrimaryColor = "#3080B9";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: secondaryColor,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 20,
      },
    },
    MuiAppBar: {
      root: {
        "&.MuiAppBar-colorPrimary": {
          color: "#000",
          backgroundColor: "#fff",
        },
      },
    },
    MuiInputLabel: {
      root: {
        "&.Mui-focused": {
          color: secondaryColor,
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: secondaryColor,
        },
      },
    },
    MuiFormLabel: {
      root: {
        "&.Mui-focused": {
          color: secondaryColor,
        },
      },
    },
    MuiFormControlLabel: {
      root: {
        marginRight: 10,
      },
    },
    MuiDialog: {
      container: {
        width: "100%",
      },
    },
    MuiTypography: {
      subtitle1: {
        color: typographyPrimaryColor,
        fontSize: "14px",
        lineHeight: "19px",
      },
      body2: {
        color: typographyPrimaryColor,
        fontSize: "16px",
      },
      h6: {
        color: secondaryColor,
      },
      colorSecondary: {
        color: typographyPrimaryColor,
      },
    },
    MuiAvatar: {
      img: {
        position: "absolute",
        backgroundSize: "cover",
        backgroundPosition: "center center",
      },
    },
  },
});

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <FullStoryWrapper org={ORG_ID} />
      <Provider store={store}>
        <ReactReduxFirebaseProvider {...props}>
          <Routes />
        </ReactReduxFirebaseProvider>
      </Provider>
    </ThemeProvider>
  );
};

export default App;
