import firebase from "firebase/app";

export class PasswordChangeError extends Error {
  public message: string;
  public name = "PasswordChangeError";

  constructor(raw: any) {
    super(raw);
    this.message = raw.message;
  }

  static generate(message: String): PasswordChangeError {
    return new PasswordChangeError(message);
  }

  toString() {
    return `PasswordChangeError: ${this.message}`;
  }
}

export const changePassword = async (password: string) => {
  const onChangePassword = firebase
    .functions()
    .httpsCallable("onChangePassword");

  try {
    const { data } = await onChangePassword({ password });

    if (!data.success) {
      throw PasswordChangeError.generate(data);
    }
  } catch (e) {
    throw e;
  }
};
