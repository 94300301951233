import { useState, useCallback, useContext } from "react";
import { __RouterContext } from "react-router";
import { useQuery } from "./helpers";

const useRouter = () => useContext(__RouterContext);

export const useTabs = (): [
  number,
  (event: React.ChangeEvent<{}>, value: any) => void
] => {
  const query = useQuery();
  const { history, location } = useRouter();
  const [tabIndex, setTabIndex] = useState(Number(query.get("status")) || 0);

  const handleChange = useCallback(
    (event: React.ChangeEvent<{}>, value: any) => {
      setTabIndex(value);
      history.replace(location?.pathname + `?status=${value}`);
    },
    [history, location]
  );

  return [tabIndex, handleChange];
};
