import React, { Fragment } from "react";
import {
  Box,
  Typography,
  TextField,
  Link,
  CircularProgress,
  Button,
} from "@material-ui/core";
import { useAppBar } from "hooks/app_bar";
import { Form } from "components/Form";
import { NativeSelect } from "components/NativeSelect";
import { useParams, Redirect, Link as RouterLink } from "react-router-dom";
import { useStrata } from "hooks/strata";
import { LoadingButton } from "components/LoadingButton";
import { useInviteMembers } from "hooks/invite";
import { ROLES } from "utils/data";
import { usePermissions } from "hooks/permissions";
import { AbsoluteContainer, Container } from "components/Container";
import { NavigationBar } from "components/NavigationBar";

const InviteMembersPage = () => {
  useAppBar(undefined, false);
  const { id } = useParams<{ id: string }>();

  const [strata, isStrataLoaded] = useStrata(id!);
  const [, isAdmin] = usePermissions(strata!);

  const [
    invites,
    errors,
    isLoading,
    ,
    handleChange,
    onSubmit,
    addInvite,
  ] = useInviteMembers(id!);

  return (
    <AbsoluteContainer>
      <NavigationBar title={"Invite members"} backTo={`/members/${id}`} />
      <Container>
        {isStrataLoaded ? (
          <>
            {!isAdmin && <Redirect to={`/members/${id}`} />}
            {isLoading === false && <Redirect to={`/members/${id}`} />}
            <Box mt={2} />
            <Typography align={"center"} variant="body2">
              {
                "Please add email addresses and roles to invite additional members."
              }
            </Typography>
            <Box mt={4} />
            <Form onSubmit={onSubmit}>
              {invites.map((invite, index) => (
                <Fragment key={index}>
                  <TextField
                    label={`Invite ${index + 1} - email address`}
                    required
                    name={"number"}
                    error={Boolean(errors[index]?.email)}
                    value={invite.email}
                    onChange={handleChange(index, "email")}
                    variant="outlined"
                    helperText={errors[index]?.email}
                  />
                  <Box mt={2} />
                  <NativeSelect
                    label={`Invite ${index + 1} - role`}
                    required
                    onChange={handleChange(index, "role")}
                    value={invite.role}
                  >
                    {ROLES.map((role) => (
                      <option key={role.code} value={role.code}>
                        {role.name}
                      </option>
                    ))}
                  </NativeSelect>
                  <Box mt={3} />
                </Fragment>
              ))}
              <Link href="#" onClick={addInvite}>
                {"Invite another team member"}
              </Link>
              <Box mt={2} />
              <LoadingButton loading={Boolean(isLoading)}>
                {"Send invites"}
              </LoadingButton>
              <Box mt={2} />
              <Button component={RouterLink} to={`/members/${id}`} color="secondary">
                {"Cancel"}
              </Button>
            </Form>
          </>
        ) : (
          <Box display={"flex"} justifyContent={"center"}>
            <CircularProgress />
          </Box>
        )}
      </Container>
    </AbsoluteContainer>
  );
};

export default InviteMembersPage;
