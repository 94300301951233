import React, { useMemo } from "react";
import {
  makeStyles,
  Avatar as MaterialAvatar,
  useTheme,
  Box,
  CircularProgress,
} from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { useFirebaseImage } from "hooks/image";
import Skeleton from "@material-ui/lab/Skeleton";
import { BackgroundImage } from "./BackgroundImage";

const useStyles = makeStyles((theme) => ({
  offset: theme.mixins.toolbar,
  small: {
    marginRight: theme.spacing(2),
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  large: {
    width: theme.spacing(9),
    height: theme.spacing(9),
  },
  avatarWhite: {
    backgroundColor: theme.palette.background.paper,
  },
  smallIcon: {
    color: theme.palette.grey[400],
    fontSize: theme.spacing(9),
  },
  largeIcon: {
    color: theme.palette.grey[400],
    fontSize: theme.spacing(12),
  },
  list: {
    margin: -theme.spacing(2),
    marginTop: 0,
  },
}));

interface AvatarProps {
  src?: string;
  absolutePath?: boolean;
  loading?: boolean;
  size?: "small" | "large";
}

export const Avatar = ({
  src,
  loading,
  absolutePath = false,
  size = "small",
}: AvatarProps) => {
  const classes = useStyles();
  const theme = useTheme();

  const [image, imageLoaded] = useFirebaseImage(absolutePath ? "" : src!);

  const avatarClassName = useMemo(
    () =>
      `${size === "large" ? classes.large : classes.small} ${!src ? classes.avatarWhite : ""
      }`,
    [size, classes, src]
  );

  const iconClassName = useMemo(
    () => (size === "large" ? classes.largeIcon : classes.smallIcon),
    [size, classes]
  );

  return (
    <Box position={"relative"}>
      <MaterialAvatar color={"primary"} className={avatarClassName}>
        {loading && <Skeleton
          animation="wave"
          variant="circle"
          width={theme.spacing(size === "large" ? 9 : 7)}
          height={theme.spacing(size === "large" ? 9 : 7)}
        />}
        {!src ? (
          <AccountCircleIcon color={"inherit"} className={iconClassName} />
        ) : !absolutePath && !imageLoaded ? (
          <Skeleton
            animation="wave"
            variant="circle"
            width={theme.spacing(size === "large" ? 9 : 7)}
            height={theme.spacing(size === "large" ? 9 : 7)}
          />
        ) : (
              <BackgroundImage src={absolutePath ? src! : image!} />
            )}
      </MaterialAvatar>
      {!imageLoaded && src && (
        <Box
          position="absolute"
          bgcolor={"rgba(255, 255, 255, 0.5)"}
          top={0}
          left={0}
          width={"100%"}
          height={"100%"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};
