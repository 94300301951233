import React, { useMemo, ReactNode, useRef, useState, useEffect } from "react";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select
} from "@material-ui/core";

let native_select_index = 0;

interface NativeSelectProps {
  label: string;
  value?: string;
  error?: boolean;
  required?: boolean;
  helperText?: string;
  inputProps?: { [key: string]: unknown };
  onChange?: (event: React.ChangeEvent<{ value: unknown }>) => void;
  children: ReactNode;
}

export const NativeSelect = ({
  label,
  value,
  error,
  helperText,
  inputProps = {},
  required = false,
  onChange,
  children
}: NativeSelectProps) => {
  const name = useMemo(() => `native-select-${native_select_index++}`, []);

  const inputLabel = useRef<HTMLLabelElement>(null);
  const [labelWidth, setLabelWidth] = useState(0);
  useEffect(() => {
    setLabelWidth(inputLabel.current!.offsetWidth);
  }, []);

  return (
    <FormControl error={error} required={required} variant="outlined">
      <InputLabel ref={inputLabel} htmlFor={name}>
        {label}
      </InputLabel>
      <Select
        native
        value={value}
        onChange={onChange}
        labelWidth={labelWidth}
        inputProps={{
          name,
          id: name,
          ...inputProps
        }}
      >
        {children}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};
