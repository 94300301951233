import React, { ReactNode } from "react";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import {
  Box,
  makeStyles,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  offset: theme.mixins.toolbar,
  title: {
    flexGrow: 1,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  },
}));

interface NavigationBarProps {
  backTo: string;
  title: ReactNode;
  action?: ReactNode;
}

export const NavigationBar = ({
  backTo,
  title,
  action,
}: NavigationBarProps) => {
  const classes = useStyles();

  return (
    <>
      <AppBar position="fixed">
        <Toolbar>
          <IconButton
            component={RouterLink}
            to={backTo}
            edge="start"
            color="inherit"
            aria-label="menu"
          >
            <ArrowBackIosIcon color="secondary" />
          </IconButton>
          <Typography className={classes.title} variant="h6">
            {title}
          </Typography>
          {action}
        </Toolbar>
      </AppBar>
      <Box className={classes.offset} />
    </>
  );
};
