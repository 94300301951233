import React, { ReactNode } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Container } from "components/Container";

interface CopyContainerProps {
  component?: any;
  flexDirection?: string;
  width?: string;
  align?: string;
  variant?: string;
  children?: ReactNode;
}

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up("sm")]: {
      width: 400,
      margin: "0 auto",
    },
  },
}));

export const CopyContainer = ({ component, flexDirection, width, align, variant, children }: CopyContainerProps) => {
  const classes = useStyles();

  return (
    <Container
      display="block"
      component={component}
      flexDirection={flexDirection}
      width={width}
      align={align}
      variant={variant}
      className={classes.container}>
        {children}
    </Container>
  );
}
