import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Route, Redirect, BrowserRouter, Switch } from "react-router-dom";
import { isLoaded, isEmpty, useFirestoreConnect } from "react-redux-firebase";
import LoginPage from "pages/login";
import DashboardPage from "pages/dashboard";
import TermsPage from "pages/terms";
import PrivacyPage from "pages/privacy";
import MainPage from "pages/main";
import NewStrataPage from "pages/new_strata";
import StrataRolePage from "pages/strata_role";
import StrataInvitePage from "pages/strata_invite";
import PersonalInvitesPage from "pages/personal_invites";
import ForgottenPasswordPage from "pages/forgotten_password";
import ResetPasswordPage from "pages/reset_password";
import MembersPage from "pages/members";
import ProfilePage from "pages/profile";
import InviteMembersPage from "pages/invite_members";
import { AppBarContext } from "state/app_bar";
import { useAppBarState } from "hooks/app_bar";
import { Box, CircularProgress } from "@material-ui/core";
import { FirestoreState, ExternalInvite, UserStrataPlan } from "utils/firebase";
import { useAuth } from "hooks/profile";
import { useLoaded } from "hooks/helpers";
import ChangePasswordPage from "pages/change_password";
import AddRequestPage from "pages/add_request";
import RequestPage from "pages/request";
import RequestEditPage from "pages/request_edit";

const PrivateRoute = ({ children, ...rest }: any) => {
  const auth = useSelector<any>((state) => state.firebase.auth);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        !isLoaded(auth) ? (
          <Box
            flex={1}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <CircularProgress />
          </Box>
        ) : !isEmpty(auth) ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

const UnAuthenticatedRoute = ({ children, ...rest }: any) => {
  const auth = useSelector<any>((state) => state.firebase.auth);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        !isLoaded(auth) ? (
          <Box
            flex={1}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <CircularProgress />
          </Box>
        ) : isEmpty(auth) ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

const FirstLoginRedirect = () => {
  const [auth, loaded] = useAuth();

  const isFirstLogin = useMemo(() => {
    return loaded && auth!.lastLoginAt === auth!.createdAt;
  }, [loaded, auth]);

  useFirestoreConnect([
    { collection: "external_invites", doc: auth?.email },
    {
      collection: "users",
      doc: auth?.uid,
      subcollections: [
        {
          collection: "strata",
          limit: 1,
        },
      ],
      storeAs: `users/${auth?.uid}/single_strata`,
    },
  ]);

  const invite = useSelector<FirestoreState, ExternalInvite | null>(
    ({ firestore: { data } }) => data?.external_invites?.[auth?.email]
  );

  const strata = useSelector<FirestoreState, UserStrataPlan[]>(
    ({ firestore: { ordered } }: any) =>
      ordered?.[`users/${auth?.uid}/single_strata`] || []
  );

  const isInvitesLoaded = useLoaded(`external_invites/${auth?.email}`);
  const isStrataLoaded = useLoaded(`users/${auth?.uid}/single_strata`);

  const isLoaded = useMemo(() => loaded && isInvitesLoaded && isStrataLoaded, [
    isInvitesLoaded,
    isStrataLoaded,
    loaded,
  ]);

  const showInvites = useMemo(
    () =>
      Boolean(isInvitesLoaded && invite && !invite?.completed && isFirstLogin),
    [invite, isFirstLogin, isInvitesLoaded]
  );

  return (
    <Box
      flex={1}
      position={"absolute"}
      top={0}
      left={0}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      bgcolor={"white"}
    >
      {!isLoaded ? (
        <CircularProgress />
      ) : (
        <Redirect
          to={{
            pathname: showInvites
              ? "/personal_invites"
              : invite || strata.length
                ? "/dashboard"
                : "/new_strata",
          }}
        />
      )}
    </Box>
  );
};

const Routes = () => {
  const app_bar = useAppBarState();

  return (
    <BrowserRouter>
      <Switch>
        <UnAuthenticatedRoute path="/login">
          <LoginPage />
        </UnAuthenticatedRoute>

        <UnAuthenticatedRoute path="/forgotten_password/:email?">
          <ForgottenPasswordPage />
        </UnAuthenticatedRoute>
        <UnAuthenticatedRoute path="/reset_password">
          <ResetPasswordPage />
        </UnAuthenticatedRoute>
        <AppBarContext.Provider value={app_bar}>
          <PrivateRoute path="/">
            <MainPage>
              <Route path="/" exact>
                <FirstLoginRedirect />
              </Route>
              <Switch>
                <Route path="/dashboard/:id?" exact>
                  <DashboardPage />
                </Route>
                <Route path="/new_strata">
                  <NewStrataPage />
                </Route>
                <Route path="/strata_role/:id">
                  <StrataRolePage />
                </Route>
                <Route path="/strata_invite/:id">
                  <StrataInvitePage />
                </Route>
                <Route path="/personal_invites">
                  <PersonalInvitesPage />
                </Route>
              </Switch>
            </MainPage>
            <Route exact path="/members/:id?">
              <MembersPage />
            </Route>
            <Route exact path="/members/:id?/invite">
              <InviteMembersPage />
            </Route>
            <Route exact path="/profile">
              <ProfilePage />
            </Route>
            <Route exact path="/change_password">
              <ChangePasswordPage />
            </Route>
            <Route exact path="/dashboard/:id/add_request">
              <AddRequestPage />
            </Route>
            <Route exact path="/dashboard/:strata_id/request/:id">
              <RequestPage />
            </Route>
            <Route exact path="/dashboard/:strata_id/request/:id/edit">
              <RequestEditPage />
            </Route>
          </PrivateRoute>
        </AppBarContext.Provider>
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
