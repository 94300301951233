import React, { useCallback, useState, useRef, useEffect } from "react";
import {
  Box,
  InputLabel,
  makeStyles,
  Link,
  CircularProgress,
} from "@material-ui/core";
import { FileUploader } from "./FileUploader";
import { useFirebaseFiles } from "hooks/image";
import { RemoteFilePreview } from "./RemoteFilePreview";
import { fileToGeneric, GenericFile, changeFileName } from "utils/helpers";

interface RequestDetails {
  attachments?: string[];
}

interface RequestDetailsEditProps {
  strataId: string;
  requestId: string;
  attachments: string[];
  onChange: (data: RequestDetails) => void;
}

const useStyles = makeStyles((theme) => ({
  image: {
    marginRight: 15,
    marginBottom: 15,
  },
  document: {
    display: "flex",
    marginBottom: 20,
  },
}));

export const RequestDocumentsEdit = ({
  strataId,
  requestId,
  attachments,
  onChange,
}: RequestDetailsEditProps) => {
  const classes = useStyles();
  const files = useRef<Array<GenericFile>>();

  const collection = `strata/${strataId}/support_requests/${requestId}`;

  const [_files, isLoaded] = useFirebaseFiles(attachments);

  useEffect(() => {
    files.current = _files.map((f) => ({
      name: f.meta.name,
      type: f.meta.contentType!,
      path: f.path,
      url: f.url,
    }));

    setImages(files.current.filter((f) => /^image\/.*$/.test(f.type)));
    setDocuments(files.current.filter((f) => !/^image\/.*$/.test(f.type)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded]);

  const onSubmit = useCallback(() => {
    onChange({
      attachments: files.current?.map(
        ({ path, name }) => path || `${collection}/${name}`
      ),
    });
  }, [onChange, files, collection]);

  const [images, setImages] = useState<Array<GenericFile>>([]);
  const [documents, setDocuments] = useState<Array<GenericFile>>([]);

  const updateFields = useCallback(
    async (genericFile: GenericFile) => {
      const file = genericFile.file;

      if (!files.current) {
        files.current = [genericFile];
      } else {
        //check if the file is the same
        if (
          files.current.find(
            (f) =>
              f.name === file?.name &&
              f.file?.lastModified === file?.lastModified &&
              f.file?.size === file?.size
          )
        ) {
          return;
        }

        files.current.push(genericFile);
      }
      if (/^image\/.*$/.test(genericFile.type)) {
        setImages((images) => [...images, genericFile]);
      } else {
        setDocuments((documents) => [...documents, genericFile]);
      }
      onSubmit();
    },
    [onSubmit]
  );

  const removeFile = useCallback(
    (file: GenericFile) => {
      if (files.current) {
        files.current = files.current.filter((f) => f !== file);
      }
      if (/^image\/.*$/.test(file.type)) {
        setImages((images) => images.filter((image) => image !== file));
      } else {
        setDocuments((documents) =>
          documents.filter((document) => document !== file)
        );
      }
      onSubmit();
    },
    [onSubmit]
  );

  return (
    <>
      <InputLabel>{"Attachments (optional)"}</InputLabel>
      <Box mt={2}>
        <Box mb={1}>
          <InputLabel>{"Photos"}</InputLabel>
        </Box>
        <Box display={"flex"} flexWrap={"wrap"} mr={"-5px"}>
          {[...images, null].map((file, i) =>
            file === null ? (
              <FileUploader
                key={i}
                onUpload={updateFields}
                collection={collection}
              />
            ) : (
              <RemoteFilePreview
                key={`${i}-${file.name}`}
                file={file.file}
                type={file.type}
                url={file.url}
                name={file.name}
                path={file.path}
                className={classes.image}
                onRemove={() => removeFile(file)}
              />
            )
          )}
        </Box>
      </Box>
      <Box mt={2} display={"flex"} flexDirection={"column"}>
        <InputLabel>{"Documents"}</InputLabel>
        <Box mt={1} display={"flex"} flexDirection={"column"}>
          {[...documents, null].map((file, i) =>
            file === null ? (
              <FileUploader
                key={i}
                imagesOnly={false}
                onUpload={updateFields}
                collection={collection}
                children={(isLoaded) =>
                  isLoaded === false ? (
                    <CircularProgress size={24} />
                  ) : (
                    <Link component={"span"}>{"Upload document"}</Link>
                  )
                }
              />
            ) : (
              <RemoteFilePreview
                key={`${i}-${file.name}`}
                file={file.file}
                url={file.url}
                type={file.type}
                name={file.name}
                path={file.path}
                className={classes.document}
                onRemove={() => removeFile(file)}
              />
            )
          )}
        </Box>
      </Box>
    </>
  );
};
