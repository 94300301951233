import React from "react";
import { Logo } from "./Logo";
import { Box, Typography, Link } from "@material-ui/core";
import { CopyContainer } from "components/CopyContainer";

export const ExistingStrata = ({ address }: { address: string }) => {
  return (
    <Box>
      <Logo />
      <Box mt={2} />
      <CopyContainer component={Typography} align={"center"} variant="body2">
        {
          "Your account has been created, however your strata plan is already on Hello Strata."
        }
      </CopyContainer>
      <CopyContainer component={Typography} align={"center"} variant="body2">
        {"We've emailed your administrator and requested access to "}
        <strong>{address}</strong>
        {". If you have any questions, please email us."}
      </CopyContainer>
      <Link
        href="mailto:contact@hellostrata.com"
        target="_blank"
        align="center"
        display="block"
      >
        {"contact@hellostrata.com"}
      </Link>
    </Box>
  );
};
