import React from "react";
import { Avatar, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    height: theme.spacing(15),
    "& img": {
      objectFit: "contain"
    }
  }
}));

export const Logo = () => {
  const classes = useStyles();
  return (
    <Avatar
      className={classes.root}
      alt="Hello Strata"
      variant="square"
      src="/logo.png"
    />
  );
};
