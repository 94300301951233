import {
  useState,
  useCallback,
  DependencyList,
  useMemo,
  useEffect,
} from "react";
import { isFunction, clearUndefined } from "utils/helpers";

export type CallbackFunction<T> = (data: T) => void;

export type ChangeCallback<T> = (
  name: keyof T
) => (event: React.ChangeEvent<{ value: unknown }>) => void;

export const useFormData = <T>(
  initData: T,
  onChange?: VoidFunction
): [T, ChangeCallback<T>, CallbackFunction<{ [P in keyof T]?: any }>] => {
  const [data, setData] = useState<T>(initData);

  const handleChange = useCallback(
    (name: keyof typeof data) => (
      event: React.ChangeEvent<{ value: unknown }>
    ) => {
      onChange?.();
      const value = event.target.value;
      setData((prev) => ({
        ...prev,
        [name]: value,
      }));
    },
    [onChange, data]
  );

  const update = useCallback(
    (data: { [P in keyof T]?: any }) => {
      onChange?.();
      setData((prev) => ({
        ...prev,
        ...clearUndefined<T>(data),
      }));
    },
    [onChange]
  );

  return [data, handleChange, update];
};

interface Dictionary<T> {
  [key: string]: T;
}

type Validator = (d: any, data?: any) => string | null;

type Validators<T extends Dictionary<any>> = {
  [P in keyof T]?: Validator | boolean;
};

export const requiredRule = (name = "Field") => <T>(data: T): string | null =>
  data ? null : `${name} is required`;

export const minLength = (length = 0, name = "Field") => (
  data: string
): string | null =>
  data.length < length
    ? `${name} is required (min ${length} characters)`
    : null;

export const maxLength = (length = Infinity) => (data: string): string | null =>
  data.length > length ? `Max length of ${length} is required` : null;

export const passwordMatch = (name = "Field") => (value: string, data: any) => {
  const lengthError = minLength(6, name)(value);
  const passwordError =
    value !== data.password
      ? "Passwords entered don't match.  Please try again"
      : null;

  return lengthError || passwordError;
};

export const validEmail = (
  data: string,
  message: string = "Email is not valid"
): string | null => {
  // eslint-disable-next-line no-useless-escape
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(data).toLowerCase()) ? null : message;
};

export type ValidationErrors<T> = { [P in keyof T]: string | undefined };

const formatErrorKey = (key: string): string => {
  return (
    key.charAt(0).toUpperCase() +
    key.slice(1).replace(/_/g, " ").replace("Str", "Street")
  );
};

export const useValidate = <T extends Dictionary<any>>(
  data: T,
  validators: Validators<T> | null = null,
  touched: boolean = false,
  defaultValidator: (name: string) => Validator = requiredRule
): ValidationErrors<T> => {
  return useMemo(
    () =>
      touched
        ? Object.keys(data).reduce((errors, key) => {
            const validator = validators?.[key];
            const readableKey = formatErrorKey(key);
            const defaultValidatorWithField = defaultValidator(readableKey);

            if (!validators) {
              return {
                ...errors,
                [key]: defaultValidatorWithField(data[key], data),
              };
            }
            if (isFunction(validator)) {
              return {
                ...errors,
                [key]: (validator as Validator)(data[key], data),
              };
            }
            return validator === undefined
              ? { ...errors, [key]: defaultValidatorWithField(data[key], data) }
              : errors;
          }, {} as ValidationErrors<T>)
        : ({} as ValidationErrors<T>),
    [data, touched, validators, defaultValidator]
  );
};

export type SubmitFunction = (e: React.FormEvent<HTMLFormElement>) => void;

export const useSubmit = <T extends (...args: any[]) => any>(
  callback: T,
  deps: DependencyList
): SubmitFunction =>
  useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      callback(e);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [callback, ...deps]
  );

export const useForm = <T>(
  initData: T,
  onChange: CallbackFunction<T>,
  validators?: Validators<T> | null,
  defaultValidator = requiredRule
): [
  T,
  ValidationErrors<T>,
  ChangeCallback<T>,
  SubmitFunction,
  CallbackFunction<{ [P in keyof T]?: any }>,
  CallbackFunction<void>
] => {
  const [touched, setTouched] = useState(false);

  const setTouchedCallback = useCallback(() => {
    if (touched) {
      setTouched(false);
    }
  }, [touched]);

  const [data, handleChange, updateChange] = useFormData<T>(
    initData,
    setTouchedCallback
  );

  const errors = useValidate(data, validators, touched, defaultValidator);

  useEffect(() => {
    if (
      touched &&
      (Object.keys(errors) as Array<keyof ValidationErrors<T>>).filter((key) =>
        Boolean(errors[key])
      ).length === 0
    ) {
      onChange?.(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [touched]);

  const onSubmit = useSubmit(() => {
    setTouched(true);
  }, []);

  const reset = useCallback(() => {
    updateChange(initData);
    setTouched(false);
  }, [initData, updateChange]);

  return [data, errors, handleChange, onSubmit, updateChange, reset];
};
