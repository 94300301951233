import { StrataPlan, StrataRoles } from "utils/firebase";
import { useAuth } from "./profile";

export const usePermissions = (strata: StrataPlan): [StrataRoles, boolean] => {
  const [auth] = useAuth();

  const role = strata?.roles[auth.uid];

  return [role, ["admin", "creator"].includes(role)];
};
