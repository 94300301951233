import React from "react";
import { Typography } from "@material-ui/core";
import { creatorDisplayName } from "utils/helpers";
import Skeleton from "@material-ui/lab/Skeleton";
import { useAuth } from "hooks/profile";
import { Requester } from "utils/firebase";

interface CreatorNameProps {
  creator: Requester;
  component?: string;
  gutterBottom?: boolean;
}

export const CreatorName = ({
  creator,
  component,
  gutterBottom = false,
}: CreatorNameProps) => {
  const [auth] = useAuth();

  let props: any = {};

  if (component) {
    props[component] = component;
  }

  return (
    <Typography {...props} gutterBottom={gutterBottom} color={"textSecondary"}>
      {(creator && creatorDisplayName(creator, auth)) || (
        <Skeleton width={250} animation="wave" />
      )}
    </Typography>
  );
};
