import React from "react";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

export const MemberListLoader = ({
  count = 1,
  className
}: {
  count: number;
  className: string;
}) => (
  <List className={className}>
    {[...Array(count).keys()].map(i => (
      <ListItem key={i}>
        <ListItemAvatar>
          <Box pr={2}>
            <Skeleton
              animation="wave"
              variant="circle"
              width={60}
              height={60}
            />
          </Box>
        </ListItemAvatar>
        <ListItemText
          primary={<Skeleton animation="wave" />}
          secondary={
            <>
              <Skeleton animation="wave" />
              <Skeleton width={50} animation="wave" />
            </>
          }
        />
      </ListItem>
    ))}
  </List>
);
