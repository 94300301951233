import React from "react";
import { Container } from "components/Container";
import {
  Box,
  makeStyles,
  Typography,
  List,
  Link,
  Button,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { Link as RouterLink, Redirect } from "react-router-dom";
import { useParams } from "react-router-dom";
import { NavigationBar } from "components/NavigationBar";
import { AbsoluteContainer } from "components/Container";
import { MemberListLoader } from "components/MemberListLoader";
import { MemberListItem } from "components/MemberListItem";
import { useStrata } from "hooks/strata";
import { useMembers } from "hooks/members";
import { useAuth } from "hooks/profile";
import { useAppBar } from "hooks/app_bar";
import { usePermissions } from "hooks/permissions";
import { Form } from "components/Form";

const useStyles = makeStyles((theme) => ({
  list: {
    margin: -theme.spacing(2),
    marginTop: 0,
  },
}));

const MembersPage = () => {
  const classes = useStyles();
  const [auth] = useAuth();

  useAppBar(undefined, false);

  const params = useParams<{ id: string }>();

  const [strata, isStrataLoaded, hasError] = useStrata(params.id);

  const [, isAdmin] = usePermissions(strata!);

  const [members, isMembersLoaded] = useMembers(params.id);

  return (
    <AbsoluteContainer>
      {isStrataLoaded && hasError && <Redirect to="/dashboard" />}
      <NavigationBar title={"Member list"} backTo={`/dashboard/${params.id}`} />
      <Container>
        <Form>
          {isStrataLoaded ? (
            <>
              <Typography variant="body2">{`${strata?.str_number} ${strata?.str_name}`}</Typography>
              <Typography variant="body2">{`${strata?.suburb} ${strata?.state} ${strata?.post_code}`}</Typography>
            </>
          ) : (
            <>
              <Typography>
                <Skeleton animation="wave" />
              </Typography>
              <Typography>
                <Skeleton animation="wave" />
              </Typography>
            </>
          )}
          {isMembersLoaded ? (
            <List className={classes.list}>
              {members?.map((member) => (
                <MemberListItem
                  key={member.id}
                  {...member}
                  strataId={params?.id}
                  current={member.id === auth.uid}
                />
              ))}
            </List>
          ) : (
            <MemberListLoader count={2} className={classes.list} />
          )}
          <Box pt={2} />
          <Typography>
            {
              "Administrators are able to invite further members to the strata plan. To remove members, administrators can email us on "
            }
            <Link href="mailto:contact@hellostrata.com">
              {"contact@hellostrata.com"}
            </Link>
          </Typography>
          {isAdmin && (
            <>
              <Box pt={3} />
              <Button
                component={RouterLink}
                to={`/members/${params.id}/invite`}
                variant="contained"
                color="primary"
              >
                {"Add members"}
              </Button>
            </>
          )}
          <Box pt={2} />
          <Button
            component={RouterLink}
            to={`/dashboard/${params.id}`}
            color="secondary"
          >
            {"Back to dashboard"}
          </Button>
          <Box pt={3} />
        </Form>
      </Container>
    </AbsoluteContainer>
  );
};

export default MembersPage;
