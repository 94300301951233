import React, { useState, useCallback, Fragment, ReactNode } from "react";
import { TextField, Box, Typography } from "@material-ui/core";
import { Form } from "components/Form";
import { useDismissibleError } from "hooks/error";
import { LoadingButton } from "./LoadingButton";
//import * as firebase from "@firebase/testing";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";


interface EmailVerificationParams {
  messages: string[];
  loading?: boolean;
  error: string | null;
  onChange?: (email: string) => void;
  footer?: ReactNode;
}


export const EmailVerification = ({
  messages,
  onChange,
  error,
  loading = false,
  footer
}: EmailVerificationParams) => {
  const [email, setEmail] = useState("");

  const [internalError] = useDismissibleError(email, error);

  const onSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      onChange?.(email);
    },
    [onChange, email]
  );

  return (
    <Form onSubmit={onSubmit}>
      {messages.map(message => (
        <Fragment key={message}>
          <Typography align={"center"} variant="body2">{message}</Typography>
          <Box mt={2} />
        </Fragment>
      ))}
      <Box mt={2} />
      <TextField
        label="Email"
        autoFocus
        error={Boolean(internalError)}
        helperText={internalError}
        value={email}
        onChange={e => setEmail(e.target.value)}
        variant="outlined"
      />
      <Box mt={2} />
      <LoadingButton
        disabled={Boolean(!email || !email.length)}
        loading={loading}
      >
        {"Next"}
      </LoadingButton>
      {footer}
    </Form>
  );
};
