import React from "react";
import { Box } from "@material-ui/core";

export const Container = (props: any) => (
  <Box
    display={"flex"}
    width={["100%", "auto"]}
    maxWidth={[600, "100%"]}
    flex={1}
    boxSizing={"border-box"}
    flexDirection={"column"}
    alignItems={["stretch", "center"]}
    p={2}
    align={["left", "center", "right"]}
    {...props}
  />
);

export const AbsoluteContainer = (props: any) => (
  <Container
    position={"absolute"}
    width={"100%"}
    minHeight={"100%"}
    p={0}
    {...props}
  />
);
