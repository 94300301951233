import { useState, useContext, useEffect } from "react";
import { IAppBarContext, AppBarContext } from "state/app_bar";

export const useAppBarState = (): IAppBarContext => {
  const [title, setTitle] = useState("");
  const [showProfile, setShowProfile] = useState(false);
  const [visibility, setVisibility] = useState(true);

  return {
    title,
    showProfile,
    setShowProfile,
    setTitle,
    visibility,
    setVisibility
  };
};

export const useAppBar = (
  newTitle?: string,
  isVisible?: boolean,
  isProfileVisible?: boolean
): [string, boolean, boolean] => {
  const {
    title,
    showProfile,
    setShowProfile,
    setTitle,
    visibility,
    setVisibility
  } = useContext(AppBarContext);

  useEffect(() => {
    if (newTitle) {
      setTitle(newTitle);
    }
  }, [setTitle, newTitle]);

  useEffect(() => {
    if (isVisible !== undefined) {
      setVisibility(isVisible);
    }
  }, [setVisibility, isVisible]);

  useEffect(() => {
    if (isProfileVisible !== undefined) {
      setShowProfile(isProfileVisible);
    }
  }, [setShowProfile, isProfileVisible]);

  return [title, visibility, showProfile];
};
