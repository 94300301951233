import React, { useState, useCallback, useMemo } from "react";
import { Container } from "components/Container";
import { Redirect, useParams } from "react-router-dom";
import { NavigationBar } from "components/NavigationBar";
import { AbsoluteContainer } from "components/Container";
import { useAppBar } from "hooks/app_bar";
import { useQuery } from "hooks/helpers";
import { clearUndefined, replaceSearch } from "utils/helpers";
import { RequestTitle, RequestData } from "components/RequestTitle";
import { RequestDetails } from "components/RequestDetails";
import { RequestDocuments } from "components/RequestDocuments";
import { useCreate } from "hooks/create";

const AddRequestPage = () => {
  const query = useQuery();
  const params = useParams<{ id: string }>();

  useAppBar(undefined, false);

  const path = `strata/${params.id}/support_requests/`;

  const [step, setStep] = useState(0);
  const [request, setRequest] = useState<RequestData>();

  const updateTitleAndStatus = useCallback((data) => {
    setRequest((request) => ({
      ...(request || {}),
      ...data,
    }));
    setStep(1);
  }, []);

  const updateDetails = useCallback((data) => {
    setRequest((request) => ({
      ...(request || {}),
      ...data,
    }));
    setStep(2);
  }, []);

  const [, create, loading, _document] = useCreate(path, true, true);

  const updateAttachments = useCallback(
    (data) => {
      const result = clearUndefined<RequestData>({
        ...request,
        ...data,
      });
      setRequest(result);
      create(result);
    },
    [create, request]
  );

  const currentStep = useMemo(() => {
    switch (step) {
      case 0:
        return (
          <RequestTitle
            title={request?.title!}
            status={request?.status!}
            back={query.get("redirect") || "/dashboard"}
            onChange={updateTitleAndStatus}
          />
        );
      case 1:
        return (
          <RequestDetails
            description={request?.details!}
            onChange={updateDetails}
            onCancel={() => setStep(0)}
          />
        );
      case 2:
        return (
          <RequestDocuments
            strataId={params.id}
            requestId={_document?.id!}
            onChange={updateAttachments}
            onCancel={() => setStep(1)}
          />
        );
      default:
        return (
          <RequestTitle
            title={request?.title!}
            back={query.get("redirect") || "/dashboard"}
            status={request?.status!}
            onChange={updateTitleAndStatus}
          />
        );
    }
  }, [
    step,
    request,
    query,
    updateTitleAndStatus,
    updateDetails,
    params,
    _document,
    updateAttachments,
  ]);

  return (
    <AbsoluteContainer>
      {loading === false && (
        <Redirect
          to={
            stateRedirect(query.get("redirect"), request?.status) ||
            "/dashboard"
          }
        />
      )}
      <NavigationBar
        title={`Add request - step ${step + 1} of 3`}
        backTo={
          stateRedirect(query.get("redirect"), request?.status) || "/dashboard"
        }
      />
      <Container>{currentStep}</Container>
    </AbsoluteContainer>
  );
};

const stateRedirect = (redirect: string | null, status: string | undefined) => {
  if (redirect === null) {
    return null;
  }
  const values: { [key: string]: number } = {
    todo: 0,
    doing: 1,
    done: 2,
    on_hold: 3,
  };

  return replaceSearch(redirect, status && `status=${values[status!]}`);
};

export default AddRequestPage;
