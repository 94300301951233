import React from "react";

export interface IAppBarContext {
  title: string;
  showProfile: boolean;
  setShowProfile: (visible: boolean) => void;
  setTitle: (title: string) => void;
  visibility: boolean;
  setVisibility: (visible: boolean) => void;
}

export const DEFAULT_STATE = {
  title: "Title",
  showProfile: false,
  setShowProfile: (visible: boolean) => {},
  setTitle: () => {},
  visibility: true,
  setVisibility: (visible: boolean) => {}
};

export const AppBarContext = React.createContext<IAppBarContext>(DEFAULT_STATE);
