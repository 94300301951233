import { useFirestoreConnect } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { User, FirestoreState } from "utils/firebase";
import { useLoaded, useError, FirestoreRecord } from "hooks/helpers";
import { useAuth } from "./profile";

export const useUser = (): FirestoreRecord<User> => {
  const [auth] = useAuth();

  useFirestoreConnect([{ collection: "users", doc: auth?.uid }]);

  const profile = useSelector<FirestoreState, User>(
    ({ firestore: { data } }) => data.users && data.users[auth?.uid]
  );

  const isLoaded = useLoaded(`users/${auth.uid}`);
  const hasError = useError(`users/${auth.uid}`);

  return [profile, isLoaded, hasError];
};
