import React, { useCallback, useState } from "react";
import { Avatar } from "components/Avatar";
import { makeStyles, Box, Button } from "@material-ui/core";
import { useAuth } from "hooks/profile";
import { useFirebaseImageUpload, useFileReader } from "hooks/file";
import { useUser } from "hooks/user";
import { useRemoveFirebaseFile } from "hooks/image";

const useStyles = makeStyles((theme) => ({
  input: {
    display: "none",
  },
}));

export const ProfileAvatar = () => {
  const classes = useStyles();
  const [auth] = useAuth();
  const [profile] = useUser();

  const [currentFile, setCurrentFile] = useState<File>();

  const [upload, isUploadLoaded] = useFirebaseImageUpload("users", auth.uid);
  const [remove, isRemoveLoaded, updateRemoveLoaded] = useRemoveFirebaseFile(
    profile?.image!
  );

  const [url] = useFileReader(currentFile!);

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      updateRemoveLoaded(null);
      const file = e.target.files?.[0];

      setCurrentFile(file);
      upload(e);
    },
    [upload, updateRemoveLoaded]
  );

  return (
    <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
      <Avatar
        size={"large"}
        absolutePath={isUploadLoaded === false && url}
        loading={isUploadLoaded === false || isRemoveLoaded === false}
        src={
          isRemoveLoaded === true
            ? ""
            : isUploadLoaded === false && url
            ? url
            : profile?.image
        }
      />
      <Box pt={2} />
      {isRemoveLoaded === null && profile?.image ? (
        <Button onClick={remove} color={"secondary"} component={"span"}>
          {"Remove photo"}
        </Button>
      ) : (
        <>
          <input
            accept="image/*"
            className={classes.input}
            id="add-photo"
            onChange={onChange}
            type="file"
          />
          <label htmlFor="add-photo">
            <Button variant={"outlined"} color={"secondary"} component={"span"}>
              {"Add photo"}
            </Button>
          </label>
        </>
      )}
    </Box>
  );
};
