import React, { useState, useCallback, Fragment, ReactNode } from "react";
import { isLoaded, isEmpty } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { Redirect, Link } from "react-router-dom";
import { Container } from "components/Container";
import { useLoginMethods } from "hooks/login_methods";
import { EmailVerification } from "components/EmailVerification";
import { LoginWithPassword } from "components/LoginWithPassword";
import { CreateProfileName } from "components/CreateProfileName";
import { CreatePassword } from "components/CreatePassword";
import { useCreateProfile, ProfileData, useProfileLogin } from "hooks/profile";
import { Logo } from "components/Logo";
import { Box, CircularProgress, Typography } from "@material-ui/core";
import { withTheme, styled } from "@material-ui/core/styles";

import SocialButton from "components/SocialButton";

import firebase from "firebase/app";
//import * as firebase from "@firebase/testing";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";

export const StyledLink = styled(withTheme(Link))((props) => ({
  color: props.theme.palette.primary.main,
}));

const LoginPage = () => {
  const auth = useSelector<any>((state) => state.firebase.auth);

  const [profileData, setProfileData] = useState<ProfileData | null>(null);

  const [methods, email, emailError, setEmailForMethods, reset] =
    useLoginMethods();

  const [isFacebook, setIsFacebook] = useState(false);
  const [pendingCred, setPendingCred] = useState("");

  const setProfileName = useCallback(
    (first_name: string, last_name: string) => {
      setProfileData({
        first_name,
        last_name,
      });
    },
    [setProfileData]
  );

  const createProfile = useCreateProfile(email.toLowerCase(), profileData);

  const [passwordError, login] = useProfileLogin(email);

  const handleGooglelLogin = (response: ReactNode) => {
    let provider = new firebase.auth.GoogleAuthProvider();
    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result: any) => {
        if (result.additionalUserInfo.isNewUser) {
          let db = firebase.firestore();
          db.collection("users").doc(result.user.uid).set({
            email: result.user.email,
            image: result.additionalUserInfo.profile.picture,
            first_name: result.additionalUserInfo.profile.given_name,
            last_name: result.additionalUserInfo.profile.family_name,
          });
          setEmailForMethods(result.user.email.toLowerCase());
        }
      })
      .catch((error) => {
        let email = error.email.toLowerCase();
        if (error.code === "auth/account-exists-with-different-credential") {
          firebase
            .auth()
            .fetchSignInMethodsForEmail(email)
            .then(function (methods) {
              if (methods[0] === "password") {
                setEmailForMethods(email);
              } else {
                setEmailForMethods(email);
                setIsFacebook(true);
                setPendingCred(error.credential);
              }
            });
        }
      });
  };

  const handleFacebookLogin = (response: ReactNode) => {
    let provider = new firebase.auth.FacebookAuthProvider();
    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result: any) => {
        if (result.additionalUserInfo.isNewUser) {
          let db = firebase.firestore();

          db.collection("users").doc(result.user.uid).set({
            email: result.user.email,
            image: result.additionalUserInfo.profile.picture?.data.url,
            first_name: result.additionalUserInfo.profile.first_name,
            last_name: result.additionalUserInfo.profile.last_name,
          });

          setEmailForMethods(result.user.email.toLowerCase());
        }

        let credential: any = result.credential;
        firebase.auth().signInWithCredential(credential);
      })
      .catch((error) => {
        let email = error.email.toLowerCase();
        if (error.code === "auth/account-exists-with-different-credential") {
          firebase
            .auth()
            .fetchSignInMethodsForEmail(email)
            .then(function (methods) {
              if (methods[0] === "password") {
                setEmailForMethods(email);
              } else {
                setEmailForMethods(email);
                setIsFacebook(true);
                setPendingCred(error.credential);
              }
            });
        }
      });
  };

  return (
    <Container>
      <Logo />
      <Box mt={2} />
      {!isLoaded(auth) ? (
        <Box
          flex={1}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <CircularProgress />
        </Box>
      ) : isEmpty(auth) ? (
        <>
          {!methods ? (
            <>
              <EmailVerification
                messages={[
                  "Hello to a better experience for strata committees and managers.",
                  "Let's get started...",
                ]}
                error={emailError}
                onChange={setEmailForMethods}
              />
              <Box mt={2} />
              <Fragment key={`OR`}>
                <Typography align={"center"} variant='body2'>{`OR`}</Typography>
                <SocialButton
                  provider={`google`}
                  onSuccess={handleGooglelLogin}
                >
                  Login with Google
                </SocialButton>
                <SocialButton
                  provider={`facebook`}
                  onSuccess={handleFacebookLogin}
                >
                  Login with Facebook
                </SocialButton>
              </Fragment>
              <Box mt={2} />
              <Typography variant='subtitle1'>
                {"By continuing, you agree with "}
                <StyledLink
                  to={{ pathname: 'https://hellostrata.com/tos' }}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Terms of Service
                </StyledLink>
                {" and "}
                <StyledLink
                  to={{ pathname: 'https://hellostrata.com/privacy' }}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Privacy Policy
                </StyledLink>
              </Typography>
            </>
          ) : methods.length ? (
            <LoginWithPassword
              error={passwordError}
              onChange={login}
              onCancel={reset}
              methods={methods}
              email={email}
              isFB={isFacebook}
              pendingCred={pendingCred}
            />
          ) : (
            <>
              {profileData ? (
                <CreatePassword
                  onChange={createProfile}
                  onCancel={() => setProfileData(null)}
                />
              ) : (
                <CreateProfileName onChange={setProfileName} onCancel={reset} />
              )}
            </>
          )}
        </>
      ) : (
        <Redirect to={"/"} />
      )}
    </Container>
  );
};

export default LoginPage;
