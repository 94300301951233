import React, { useMemo } from "react";
import { Box, makeStyles } from "@material-ui/core";
import { useFirebaseFiles } from "hooks/image";
import { FilePreviewComponent } from "./FilePreviewComponent";
import { GalleryPreview } from "./GalleryPreview";

interface GalleryProps {
  attachments: string[];
}

const useStyles = makeStyles((theme) => ({
  space: {
    marginTop: 10,
  },
}));

export const Gallery = ({ attachments }: GalleryProps) => {
  const classes = useStyles();
  const [files, isLoaded] = useFirebaseFiles(attachments);

  const images = useMemo(
    () =>
      files.filter((file) => /^image\/.*$/.test(file.meta.contentType || "")),
    [files]
  );

  const documents = useMemo(
    () =>
      files.filter((file) => !/^image\/.*$/.test(file.meta.contentType || "")),
    [files]
  );

  return (
    <>
      {isLoaded ? (
        <>
          <GalleryPreview images={images} />
          {documents.map((document, i) => (
            <FilePreviewComponent
              className={classes.space}
              key={`${document.url}-${i}`}
              url={document.url}
              type={document.meta.contentType!}
              name={document.meta.name}
              onClick={() => {
                window.open(document.url, "_blank");
              }}
            />
          ))}
        </>
      ) : (
        <Box>{"Loading..."}</Box>
      )}
    </>
  );
};
