import React from "react";
import { Link } from "react-router-dom";
import { EmailVerification } from "components/EmailVerification";
import { Container, Box, Button, Typography } from "@material-ui/core";
import { Logo } from "components/Logo";
import { useForgottenPassword } from "hooks/profile";

const ForgottenPasswordPage = () => {
  const [email, isLoading, error, setEmailForReset] = useForgottenPassword();

  return (
    <Container>
      <Box mt={2} />
      <Logo />
      <Box mt={2} />
      {isLoading === false ? (
        <>
          <Typography align={"center"} variant="body2">
            {"We've sent a password reset link to"}
          </Typography>
          <Typography align={"center"}>
            <strong>{email}</strong>
          </Typography>
          <Box mt={2} />
        </>
      ) : (
        <EmailVerification
          messages={["No problem, we'll reset your password."]}
          error={error}
          loading={Boolean(isLoading)}
          onChange={setEmailForReset}
          footer={
            <>
              <Box mt={2} />
              <Button component={Link} to="/" color="secondary">
                {"Cancel"}
              </Button>
            </>
          }
        />
      )}
    </Container>
  );
};

export default ForgottenPasswordPage;
