import React, { useCallback, useState } from "react";
import { useAppBar } from "hooks/app_bar";
import { TextField, Box, Typography } from "@material-ui/core";
import { NativeSelect } from "components/NativeSelect";
import { Form } from "components/Form";
import { LoadingButton } from "components/LoadingButton";
import { useCreate } from "hooks/create";
import { Redirect } from "react-router-dom";
import { useForm } from "hooks/form";
import { Logo } from "components/Logo";
import { STATES } from "utils/data";
import {
  validateAddress,
  StrataPlanData,
  AddressValidateError,
  toAddressString,
} from "services/address";
import { ExistingStrata } from "components/ExistingStrata";

const INIT_DATA = {
  str_number: "",
  str_name: "",
  suburb: "",
  state: "",
  post_code: "",
};

const NewStrataPage = () => {
  useAppBar("Add a strata plan", true, false);

  const [isSubmitting, setSubmitting] = useState(false);
  const [existingStrata, setExistingStrata] = useState("");

  const [document, create, isLoading] = useCreate("/strata");

  const handleSubmit = useCallback(
    async (data: StrataPlanData) => {
      data.str_name = data.str_name.toString().trim();
      data.str_number = data.str_number.toString().trim();
      data.suburb = data.suburb.toString().trim();
      data.post_code = data.post_code.toString().trim();
      setSubmitting(true);

      try {
        await validateAddress(data);
        create(data);
      } catch (e) {
        if (e instanceof AddressValidateError) {
          setExistingStrata(toAddressString(data));
        }
      }
      setSubmitting(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const [strataPlan, errors, handleChange, onSubmit] = useForm<StrataPlanData>(
    INIT_DATA,
    handleSubmit
  );

  return existingStrata ? (
    <ExistingStrata address={existingStrata} />
  ) : (
      <Form onSubmit={onSubmit}>
        {document && <Redirect to={`/strata_role/${document.id}`} />}
        <Logo />
        <Box mt={2} />
        <Typography align={"center"} variant="body2">
          {"Add your strata plan to Hello Strata"}
        </Typography>
        <Box mt={4} />
        <TextField
          label="Street number"
          autoFocus
          required
          name={"number"}
          error={Boolean(errors.str_number)}
          value={strataPlan.str_number}
          onChange={handleChange("str_number")}
          inputProps={{
            autoComplete: "number",
          }}
          variant="outlined"
          helperText={
            errors.str_number ||
            "Exclude your lot number e.g. 24. rather than 3/24."
          }
        />
        <Box mt={2} />
        <TextField
          label="Street name"
          required
          inputProps={{
            autoComplete: "street-address",
          }}
          error={Boolean(errors.str_name)}
          value={strataPlan.str_name}
          onChange={handleChange("str_name")}
          variant="outlined"
          helperText={errors.str_name}
        />
        <Box mt={2} />
        <TextField
          label="Suburb"
          required
          error={Boolean(errors.suburb)}
          value={strataPlan.suburb}
          onChange={handleChange("suburb")}
          inputProps={{
            autoComplete: "address-level2",
          }}
          variant="outlined"
          helperText={errors.suburb}
        />
        <Box mt={2} />
        <NativeSelect
          label="State"
          required
          error={Boolean(errors.state)}
          value={strataPlan.state}
          onChange={handleChange("state")}
          helperText={errors.state}
        >
          <option value="" />
          {STATES.map((state) => (
            <option key={state.postal} value={state.postal}>
              {state.postal}
            </option>
          ))}
        </NativeSelect>
        <Box mt={2} />
        <TextField
          label="Postcode"
          required
          inputProps={{
            maxLength: 4,
            autoComplete: "postal-code",
          }}
          error={Boolean(errors.post_code)}
          value={strataPlan.post_code}
          onChange={handleChange("post_code")}
          variant="outlined"
          helperText={errors.post_code}
        />
        <LoadingButton loading={isSubmitting || Boolean(isLoading)}>
          {"Next"}
        </LoadingButton>
      </Form>
    );
};

export default NewStrataPage;
