import React, { Fragment, useMemo } from "react";
import { Logo } from "components/Logo";
import {
  Box,
  Typography,
  TextField,
  ListItem,
  ListItemText,
  CircularProgress,
  Link,
} from "@material-ui/core";
import { Form } from "components/Form";
import { LoadingButton } from "components/LoadingButton";
import { useAppBar } from "hooks/app_bar";
import { useExternalInvites } from "hooks/invite";
import { Redirect } from "react-router-dom";
import { ROLES } from "utils/helpers";
import { CopyContainer } from "components/CopyContainer";
import { useSingleStrata } from "hooks/single_strata";

const PersonalInvitesPage = () => {
  useAppBar("", false);

  const [strata, isStrataLoaded] = useSingleStrata();

  const [
    invites,
    ,
    isLoading,
    isUpdateLoading,
    ,
    handleChange,
    onSubmit,
  ] = useExternalInvites();

  const isReady = useMemo(() => {
    return isStrataLoaded && strata && isUpdateLoading === false;
  }, [isStrataLoaded, strata, isUpdateLoading]);

  console.log("personal_invites", strata, isStrataLoaded, isUpdateLoading);

  return isLoading ? (
    <Box
      flex={1}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <CircularProgress />
    </Box>
  ) : (
    <Box>
      {isReady && <Redirect to={`/dashboard/${strata.id}`} />}
      <Box mt={2} />
      <Logo />
      <Box mt={2} />
      <CopyContainer component={Typography} align={"center"} variant="body2">
        {
          "We've found the following strata plans and roles assigned to you. If relevant, please enter the lot number you own."
        }
      </CopyContainer>
      <Form onSubmit={onSubmit}>
        {invites.map(({ id, role, lot, strata }) => {
          return (
            <Fragment key={id}>
              <ListItem>
                <ListItemText primary={strata} secondary={ROLES[role]} />
              </ListItem>
              <Box mt={2} />
              <TextField
                label="Lot/unit number (optional)"
                value={lot}
                name={"number"}
                onChange={handleChange(id, "lot")}
                variant="outlined"
                helperText="If you own multiple, please only note 1 lot."
              />
              <Box mt={3} />
            </Fragment>
          );
        })}
        <Typography>
          {"Anything incorrect or missing? Please email us."}
        </Typography>
        <Link href="mailto:contact@hellostrata.com" target="_blank">
          {"contact@hellostrata.com"}
        </Link>
        <LoadingButton loading={isUpdateLoading != null && !isReady}>
          {"View my dashboard(s)"}
        </LoadingButton>
      </Form>
    </Box>
  );
};

export default PersonalInvitesPage;
