import React, { useState, useEffect } from "react";
import {
  Box,
  List,
  makeStyles,
  ListSubheader,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { useComments } from "hooks/comments";
import { formatDate } from "utils/helpers";
import { PostComment } from "./PostComment";
import { Avatar } from "./Avatar";
import { CreatorName } from "./CreatorName";
import { FormattedTypography } from "./FormatedTypography";

interface CommentsListProps {
  count: number;
  strataId: string;
  supportRequestId: string;
}

const useStyles = makeStyles((theme) => ({
  commentList: {
    width: "100%",
    paddingBottom: 90,
  },
  commentListFocused: {
    width: "100%",
    paddingBottom: 210,
  },
  commentItem: {
    alignItems: "start",
  },
  commentItemAvatar: {
    marginTop: theme.spacing(1),
  },
  message: {
    display: "block",
  },
}));

export const CommentsList = ({
  strataId,
  supportRequestId,
  count,
}: CommentsListProps) => {
  const classes = useStyles();

  const [comments, isLoaded] = useComments(strataId, supportRequestId);
  const [focused, setFocused] = useState(false);
  const [commented, setCommented] = useState(false);
  const [commentsCount, setCommentsCount] = useState(count);

  return (
    <>
      <Box display={"flex"} alignSelf={"stretch"} mt={1} mx={-2}>
        <List
          className={focused ? classes.commentListFocused : classes.commentList}
          subheader={
            <ListSubheader>
              {
                (!isLoaded || commented && (commentsCount != count)) ?
                  <Skeleton width={300} animation="wave" />
                  :

                  <>
                    {`${count || 0} comment${count === 1 ? "" : "s"
                      }`}
                  </>
              }

            </ListSubheader>
          }
        >

          {isLoaded &&
            comments.map((comment) => {
              return (
                <ListItem
                  key={comment.id}
                  divider
                  className={classes.commentItem}
                >
                  <ListItemAvatar className={classes.commentItemAvatar}>
                    <Avatar src={comment.creator.image} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <CreatorName creator={comment.creator} component={"span"} />
                    }
                    secondary={
                      <>
                        <FormattedTypography
                          component={"span"}
                          color={"textPrimary"}
                          className={classes.message}
                          gutterBottom
                        >
                          {comment.message ? (
                            comment.message
                          ) : (
                              <Skeleton width={80} animation="wave" />
                            )}
                        </FormattedTypography>
                        <Typography component={"span"}>
                          {comment.created_at ? (
                            formatDate(comment.created_at.toDate())
                          ) : (
                              <Skeleton width={80} animation="wave" />
                            )}
                        </Typography>
                      </>
                    }
                  />
                </ListItem>
              )
            }
            )}
        </List>
      </Box>
      <PostComment
        strataId={strataId}
        supportRequestId={supportRequestId}
        onFocus={setFocused}
        hasCommented={setCommented}
        hasCount={setCommentsCount}
        curCount={commentsCount}
      />
    </>
  );
};
