import React, { useCallback } from "react";
import { TextField, Box, Button, Typography } from "@material-ui/core";
import { useForm } from "hooks/form";
import { Form } from "./Form";

interface IRequestDetails {
  details: string;
}

interface RequestDetailsProps {
  description: string;
  onChange: (data: IRequestDetails) => void;
  onCancel: () => void;
}

const REQUEST_VALIDATORS = {
  details: false,
};

export const RequestDetails = ({
  description,
  onChange,
  onCancel,
}: RequestDetailsProps) => {
  const update = useCallback(
    (data: IRequestDetails) => {
      onChange(data);
    },
    [onChange]
  );

  const [data, errors, handleChange, onSubmit] = useForm<IRequestDetails>(
    { details: description || "" },
    update,
    REQUEST_VALIDATORS
  );

  return (
    <Form onSubmit={onSubmit}>
      <Typography component="div" variant="body2">
        <Box>
          {
            "Where possible, provide further information to assist strata management and your committee to resolve the issue."
          }
        </Box>
      </Typography>
      <Box mt={6} />
      <TextField
        label="Details (optional)"
        value={data.details}
        error={Boolean(errors.details)}
        helperText={errors.details}
        variant="outlined"
        multiline
        rowsMax={8}
        onChange={handleChange("details")}
      />
      <Box mt={4} />
      <Button type="submit" variant="contained" color={"primary"}>
        {"Next"}
      </Button>
      <Box mt={2} />
      <Button color="secondary" onClick={onCancel}>
        {"Back"}
      </Button>
    </Form>
  );
};
