import React, { useCallback } from "react";
import { Box, makeStyles, Typography, IconButton } from "@material-ui/core";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import GridOnIcon from "@material-ui/icons/GridOn";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import CancelIcon from "@material-ui/icons/Cancel";
import { useFileReader } from "hooks/file";
import { classnames } from "utils/helpers";
import { useRemoveFirebaseFile } from "hooks/image";

const icons: { [key: string]: any } = {
  "application/msword": InsertDriveFileIcon,
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": InsertDriveFileIcon,
  "application/pdf": PictureAsPdfIcon,
  "application/vnd.ms-excel": GridOnIcon,
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": GridOnIcon,
};

export const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    height: 80,
  },
  button: {
    width: 80,
    height: 80,
    display: "flex",
    flexShrink: 0,
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    overflow: "hidden",
    borderRadius: 10,
    border: "1px solid #ccc",
  },
  image: {
    width: "100%",
    height: "100%",
    backgroundPosition: "50% 50%",
    backgroundSize: "cover",
  },
  input: {
    display: "none",
  },
  remove: {
    position: "absolute",
    top: -10,
    right: -10,
  },
  icon: {
    color: "#fff",
  },
}));

interface RequestDetailsProps {
  className?: string;
  name: string;
  file: File;
  collection: string;
  onRemove: (file: File) => void;
}

export const FilePreview = ({
  className,
  file,
  name,
  collection,
  onRemove,
}: RequestDetailsProps) => {
  const classes = useStyles();

  const [url] = useFileReader(file);
  const [remove] = useRemoveFirebaseFile(`${collection}/${name}`);

  const handleRemove = useCallback(() => {
    remove();
    onRemove(file);
  }, [remove, onRemove, file]);

  const Icon =
    file && file.type
      ? icons[file.type] || InsertDriveFileIcon
      : InsertDriveFileIcon;

  return (
    <Box className={classnames(classes.root, className)}>
      <Box className={classes.button}>
        {url ? (
          <div
            className={classes.image}
            style={{ backgroundImage: `url("${url}")` }}
          />
        ) : (
          <Icon fontSize={"large"} />
        )}
      </Box>
      {Boolean(file && file.type) && !Boolean(/^image\/.*$/.test(file.type)) && (
        <Box pl={2} overflow={"hidden"} pr={3}>
          <Typography
            component={Box}
            overflow={"hidden"}
            textOverflow={"ellipsis"}
          >
            {file.name}
          </Typography>
        </Box>
      )}
      <IconButton className={classes.remove} onClick={handleRemove}>
        <CancelIcon className={url ? classes.icon : ""} />
      </IconButton>
    </Box>
  );
};
