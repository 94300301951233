import React, { ReactNode } from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: 400,
      margin: "0 auto",
    },
  },
}));

interface FormProps {
  onSubmit?: (event: React.FormEvent<HTMLFormElement>) => void;
  children?: ReactNode;
}

export const Form = ({ onSubmit, ...props }: FormProps) => {
  const classes = useStyles();

  return (
    <form
      className={classes.form}
      noValidate
      autoComplete="off"
      onSubmit={onSubmit}
      {...props}
    />
  );
};
