import React from "react";
import { Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    whiteSpace: "pre-wrap",
  },
}));

export const FormattedTypography = ({ className, ...props }: any) => {
  const classes = useStyles();

  if (className) {
    className = `${className} ${classes.root}`;
  } else {
    className = classes.root;
  }

  return <Typography {...props} className={className} />;
};
