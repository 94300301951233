import React, { useCallback, useRef, useEffect } from "react";
import { useAppBar } from "hooks/app_bar";
import {
  TextField,
  Box,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { NativeSelect } from "components/NativeSelect";
import { Form } from "components/Form";
import { Redirect, useParams } from "react-router-dom";
import { useForm } from "hooks/form";
import { Logo } from "components/Logo";
import { useStrata, useCreator } from "hooks/strata";
import { useUpdate } from "hooks/update";
import { useAuth } from "hooks/profile";
import { LoadingButton } from "components/LoadingButton";
import { Member } from "utils/firebase";
import { ROLES } from "utils/data";

const INIT_DATA = {
  role: ROLES[0].code,
  lot: "",
};

interface MemberRole {
  role: string;
  lot: string;
}

const VALIDATORS = {
  lot: false,
};

const StrataRolePage = () => {
  useAppBar("Add a strata plan");
  let { id } = useParams();

  const [document, isLoaded] = useStrata(id!);

  const [auth] = useAuth();
  const isCreator = useCreator(document!);

  const [doc, update, isLoading] = useUpdate<Member>({
    collection: "strata",
    doc: id,
    subcollections: [
      {
        collection: "members",
        doc: auth?.uid,
      },
    ],
    storeAs: `strata/${id}/members/${auth?.uid}`,
  });

  const handleSubmit = useCallback(
    (data: MemberRole) => {
      update(data);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const [member, errors, handleChange, onSubmit, handleUpdate] = useForm<
    MemberRole
  >(INIT_DATA, handleSubmit, VALIDATORS);

  const prevDocRef = useRef<Member>();

  useEffect(() => {
    if (!prevDocRef.current && doc) {
      handleUpdate({
        lot: doc!.lot,
        role: doc!.role,
      });
      prevDocRef.current = doc;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doc, prevDocRef]);

  return (
    <Form onSubmit={onSubmit}>
      {isLoaded ? (
        <>
          {!isCreator && <Redirect to={`/dashboard`} />}
          {isLoading === false ? (
            <Redirect to={`/strata_invite/${id}`} />
          ) : (
            <></>
          )}
          <Logo />
          <Box mt={2} />
          <Typography align={"center"} variant="body2">
            {
              "What is your role on the committee? If relevant, please enter the lot number you own."
            }
          </Typography>
          <Box mt={4} />
          <NativeSelect
            label="Role"
            required
            error={Boolean(errors.role)}
            value={member.role}
            onChange={handleChange("role")}
            helperText={errors.role}
          >
            {ROLES.map((role) => (
              <option key={role.code} value={role.code}>
                {role.name}
              </option>
            ))}
          </NativeSelect>
          <Box mt={2} />
          <TextField
            label="Lot/unit number (optional)"
            name={"number"}
            error={Boolean(errors.lot)}
            value={member.lot}
            onChange={handleChange("lot")}
            variant="outlined"
            helperText={errors.lot}
          />
          <Box mt={2} />
          <LoadingButton loading={Boolean(isLoading)}>{"Next"}</LoadingButton>
        </>
      ) : (
        <Box display={"flex"} justifyContent={"center"}>
          <CircularProgress />
        </Box>
      )}
    </Form>
  );
};

export default StrataRolePage;
