import { useSelector } from "react-redux";
import { FirestoreState, FirebaseError } from "utils/firebase";
import { useLocation } from "react-router-dom";

export type FirestoreRecord<T> = [T, boolean, FirebaseError | null | undefined];

export const useLoaded = (path: string) =>
  useSelector<FirestoreState, boolean>(
    ({ firestore: { status } }) =>
      !status.requesting[path] && status.requested[path]
  );

export const useError = (path: string) =>
  useSelector<FirestoreState, FirebaseError | null | undefined>(
    ({ firestore: { errors } }) => errors.byQuery[path]
  );

export const useQuery = () => new URLSearchParams(useLocation().search);

export const useFullLocation = () => {
  const location = useLocation();

  return `${location.pathname}${location.search}`;
};
